import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'

import { icons, CustomHelmet, Loading, Button, Modal, LinkButton } from '@openbox-app-shared'

import { log, error } from '../../../log'
import beep from '../../../beep'
import dashboardIcons from '../../../icons'
import { dispatch, subscribe } from '../../../redux'
import CardsComponents from './cards'
import UserCircle from '../../../components/UserCircle'
import CoolCart from '../../../components/CoolCart'
import H2 from '../../../components/H2'
import EposCart from '../opsManager/components/Cart'

const DO_REFRESH = true
const REFRESH_INTERVAL = 5 * 1000

const lsLoad = (k) => window.sticky.internals.readFromLocalStorage(`dashboard--stickyretail-${k}`)
const lsSave = (k, v) => window.sticky.internals.writeToLocalStorage(`dashboard--stickyretail-${k}`, v)

const Cards = {
  Payment: {
    Component: CardsComponents.Payment,
    doAction: (thisState, entity, action) => {
      return new Promise(resolve => {
        action === 'print' && (() => {
          dispatch('TRIGGER', { trigger: 'printPayment', body: { paymentId: entity.id } })
        })()
        action === 'tags' && (() => {
          dispatch(
            'CHOOSE_TAGS',
            {
              why: 'stickyretail--Payment--tags',
              entity,
              tags: entity.tags
            }
          )
        })()
        action === 'done' && (() => {
          let finalIsDone = !entity.newStatusDone
          let tagsCompletedAlreadyBackup = entity.customDataPublic.readFrom('tagsCompletedAlready') || []
          let finalIsDoneBackup = entity.newStatusDone
          let tagsCompletedAlready = entity.customDataPublic.readFrom('tagsCompletedAlready') || []
          if (thisState.completeByTag && thisState.filterByProductTags.size > 0) {
            // console.warn('[DebugLater4] 1 tagsCompletedAlready', tagsCompletedAlready, thisState.filterByProductTags)
            if (tagsCompletedAlready.length === 0) {
              tagsCompletedAlready = Array.from(new Set([...tagsCompletedAlready, ...thisState.filterByProductTags.toArray()]))
              entity.newStatusDoneAt = Math.floor(window.sticky.dateTime.getNowUtcLegacy() / 1000)
            } else {
              if (thisState.filterByProductTags.toArray().every(_ => tagsCompletedAlready.includes(_))) {
                tagsCompletedAlready = tagsCompletedAlready.filter(_ => !thisState.filterByProductTags.has(_))
                entity.newStatusDoneAt = undefined
              } else {
                tagsCompletedAlready = Array.from(new Set([...tagsCompletedAlready, ...thisState.filterByProductTags.toArray()]))
                entity.newStatusDoneAt = Math.floor(window.sticky.dateTime.getNowUtcLegacy() / 1000)
              }
            }
            if (tagsCompletedAlready.length === 0) {
              finalIsDone = true
            } else {
              finalIsDone = false
            }
          } else {
            entity.newStatusDoneAt = !finalIsDone ? Math.floor(window.sticky.dateTime.getNowUtcLegacy() / 1000) : undefined
          }
          // console.warn('[DebugLater4] 2 tagsCompletedAlready', tagsCompletedAlready)
          entity.customDataPublic.writeTo('tagsCompletedAlready', tagsCompletedAlready)
          entity.newStatusDone = finalIsDone
          dispatch('LOADING')
          Cards.Payment.patch(entity, false, ['newStatusDone', 'newStatusDoneAt', 'customDataPublic'])
            .then(() => {
              dispatch('STOP_LOADING')
              resolve('forceUpdate')
            })
            .catch (({ message }) => {
              window.sticky.applications.blocks.showError(`The completed state of this payment couldn't be changed.\n\nDo you have an internet connection?\n\n(${message})`, true)
              entity.customDataPublic.writeTo('tagsCompletedAlready', tagsCompletedAlreadyBackup)
              entity.newStatusDone = finalIsDoneBackup
            })
            .then(() => {
              dispatch('STOP_LOADING')
              resolve('forceUpdate')
            })
        })()
        action === 'ciLocalChange' && (() => {
          const forceJson = entity.toJson(['cart'])
          forceJson.cart = entity.rawCart
          dispatch('LOADING')
          window.sticky.pay.save(entity, false, ['cart'], forceJson)
            .then(() => {
              dispatch('STOP_LOADING')
              resolve('forceUpdate')
            })
        })()
        action === 'changeFederatedUser' && (() => {
          dispatch('CHOOSE_FEDERATED_USER', { why: 'stickyretail--changeFederatedUser--Payment', entity, canBeClosed: true })
        })()
        action === 'removeFederatedUser' && (() => {
          entity.lastFederatedUserId = null
          dispatch('LOADING')
          Cards.Payment.patch(entity, false, ['lastFederatedUserId'])
            .then(() => {
              dispatch('STOP_LOADING')
              resolve('getCards')
            })
        })()
        action === 'session' && (() => {
          dispatch('LOADING')
          const { sessionId } = entity
          window.sticky.session.get(undefined, false, sessionId)
            .then(session => {
              log('[Card->Payment] (teamMember)', { sessionId, session })
              dispatch('SESSION', { session })
              dispatch('STOP_LOADING')
              resolve()
            })
        })()
        action === 'pay' && (() => {
          entity.sessionPaidAt = Math.floor((+window.sticky.dateTime.getNowUtcLegacy()) / 1000)
          dispatch('LOADING')
          Cards.Payment.patch(entity, false, ['sessionPaidAt'])
            .then(() => {
              dispatch('STOP_LOADING')
              resolve('forceUpdate')
            })
        })()
        action === 'note' && (async () => {
          const { Message: paymentExtra } = await window.sticky.applications.blocks.getInput(
            undefined,
            'Message',
            entity.extra || '',
            'Text',
            'Done',
            undefined,
            undefined,
            undefined,
            false
          )
          entity.extra = paymentExtra
          await Cards.Payment.patch(entity, false, ['extra'])
          resolve('forceUpdate')
        })()
      })
    },
    patch: (...args) => {
      return window.sticky.pay.save(...args)
    },
    isDone: (entity, thisState) => {
      const tagsCompletedAlready = entity.customDataPublic.readFrom('tagsCompletedAlready') || []
      // console.warn('[DebugLater4] 3 tagsCompletedAlready', tagsCompletedAlready)
      if (thisState.completeByTag) {
        if (thisState.filterByProductTags.size === 0) {
          return entity.newStatusDone
        } else {
          return thisState.filterByProductTags.toArray().every(_ => tagsCompletedAlready.includes(_))
        }
      } else {
        return entity.newStatusDone
      }
    }
  },
  EVENT_TO_DO: {
    Component: CardsComponents.EVENT_TO_DO,
    patch: (...args) => {
      return window.sticky.session.events.save(...args)
    },
    doAction: (thisState, entity, action, ...args) => {
      return new Promise(resolve => {
        action === 'done' && (() => {
          entity.customData.writeTo('newStatusDone', true)
          dispatch('LOADING')
          Cards.EVENT_TO_DO.patch(entity)
            .then(() => {
              dispatch('STOP_LOADING')
              resolve('forceUpdate')
            })
        })()
        action === 'changeFederatedUser' && (() => {
          dispatch('CHOOSE_FEDERATED_USER', { why: 'stickyretail--changeFederatedUser--EVENT_TO_DO', entity, canBeClosed: true })
        })()
        action === 'removeFederatedUser' && (() => {
          entity.federatedUserId = null
          dispatch('LOADING')
          Cards.EVENT_TO_DO.patch(entity)
            .then(() => {
              dispatch('STOP_LOADING')
              resolve('getCards')
            })
        })()
        action === 'session' && (() => {
          dispatch('LOADING')
          const { sessionId } = entity
          window.sticky.session.get(undefined, false, sessionId)
            .then(session => {
              log('[Card->EVENT_TO_DO] (teamMember)', { sessionId, session })
              dispatch('SESSION', { session })
              dispatch('STOP_LOADING')
              resolve()
            })
        })()
        action === 'event' && (() => {
          dispatch('LOADING')
          const [eventId] = args
          window.sticky.session.events.get(eventId)
            .then(event => {
              log('[Card->EVENT_TO_DO] (moreEventId)', { event })
              dispatch('EVENT', { event })
              dispatch('STOP_LOADING')
              resolve()
            })
        })()
      })
    },
    isDone: (entity, thisState) => entity.customData.readFrom('newStatusDone') === true
  }
}

const StyledRoute = styled.div`
  .to-dos {
    margin: 0 1rem 0 1rem;
    > * {
      display: inline-block;
      vertical-align: top;
      margin-bottom: 1rem;
    }
  }
  @media only screen and (min-width: 50rem) {
    .to-dos {
      > * {
        width: 23.5rem;
        margin-right: 1rem;
      }
    }
  }
  .controls {
    width: 100%;
    padding: 1rem 1rem 0 1rem;
    > * {
      display: inline-block;
      vertical-align: top;
      margin: 0 1rem 1rem 0;
    }
    > span, > strong {
      height: 2.5rem;
      line-height: 2.5rem;
      color: white;
      &.big {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        color: black;
        background-color: yellow;
        font-size: 150%;
        border-radius: 6px;
        box-shadow: 0 3px 6px 0 rgba(60, 68, 86, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
  .choose-thing img {
    width: 0.9375rem;
  }
  .beep-yes-no {
    p {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    .buttons {
      text-align: center;
      > * {
        display: inline-block;
        vertical-align: top;
      }
      > * + * {
        margin-left: 1rem;
      }
    }
  }
  .totaliser-data {
    width: calc(100% - 2rem);
    max-width: 376px;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(4px);
    overflow: scroll;
    border-radius: 6px;
    box-shadow: 0 3px 6px 0 rgba(60, 68, 86, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    border: 0.25rem solid #363d44;
    .component--h2 {
      color: black;
      margin-bottom: 0.5rem;
    }
    .component--button {
      position: absolute;
      top: 0.5rem;
      right: 1rem;
    }
  }
`

export default class Route extends Component {
  constructor() {
    super()
    const url = new URL(window.location.href)
    const overrideApplicationId = url.searchParams.get('overrideApplicationId')
    this.state = {
      howManyCardsBefore: undefined,
      showingDummyModal: true,
      filteringByFederatedUser: lsLoad('filteringByFederatedUser') || false,
      onlyShowEvents: lsLoad('onlyShowEvents') || false,
      onlyShowPayments: lsLoad('onlyShowPayments') || false,
      hideCompleted: lsLoad('hideCompleted') || false,
      completeByTag: lsLoad('completeByTag') || false,
      autoExpandSession: lsLoad('autoExpandSession') || false,
      overrideApplicationId,
      filterByProductTags: window.sticky.newPatchableSet(lsLoad('filterByProductTags') || [])
    }
    this.isWorking = false
    this.getCards = this.getCards.bind(this)
  }

  async getAllPayments(finalState, autoExpandSession) {
    const { filterByProductTags, onlyShowEvents } = finalState
    if (onlyShowEvents) {
      return []
    }

    // cache buster for `fillCartProducts` function
    await window.sticky.products.getAll(true)

    const { userPreferences } = this.props
    const parts = [true].includes(userPreferences.toDoShowOnlyUnpaidGATEWAY_LATER) ? ['gateway=GATEWAY_LATER'] : ['newStatusDone=!!!null', 'sessionPaidAt=any']
    finalState.filteringByApplication && parts.push(`applicationId=${finalState.filteringByApplication.id}`)
    finalState.overrideApplicationId && parts.push(`applicationId=${finalState.overrideApplicationId}`)
    finalState.filteringByThing && parts.push(`thingId=${finalState.filteringByThing.id}`)
    finalState.filteringByFederatedUser && parts.push(`lastFederatedUserId=${finalState.currentFederatedUser.id}`)
    window.sticky.pay.setQuery(parts.join('&'))
    let toReturn = [...(await window.sticky.pay.getAll(true))].reverse()
    if (filterByProductTags.hasAny) {
      toReturn = toReturn.filter(_ => _.rawCart.some(ci => ci.product ? ci.product.tags.toArray().some(pTag => filterByProductTags.has(pTag)) : true))
    }
    if (autoExpandSession) {
      for (let i = 0; i < toReturn.length; i++) {
        toReturn[i].autoExpandedSession = await window.sticky.session.get(undefined, true, toReturn[i].sessionId)
      }
    }
    return toReturn
  }
  async getAllEvents(finalState, autoExpandSession) {
    const { onlyShowPayments } = finalState
    if (onlyShowPayments) {
      return []
    }
    const queryName = (finalState.filteringByApplication || finalState.filteringByThing) ? 'type-application-thing' : 'type'
    const query = {
      type: 'TO_DO',
      applicationId: finalState.filteringByApplication ? finalState.filteringByApplication.id : undefined,
      thingId: finalState.filteringByThing ? finalState.filteringByThing.id : undefined,
      federatedUserId: finalState.filteringByFederatedUser ? finalState.currentFederatedUser.id : undefined
    }
    let toReturn = await window.sticky.session.events.getAll(queryName, query, true)
    if (autoExpandSession) {
      for (let i = 0; i < toReturn.length; i++) {
        toReturn[i].autoExpandedSession = await window.sticky.session.get(undefined, true, toReturn[i].sessionId)
      }
    }
    return toReturn
  }

  async getCardsData(thisState) {
    const { autoExpandSession } = thisState
    const allPayments = await this.getAllPayments(thisState, autoExpandSession)
    const allEvents = await this.getAllEvents(thisState, autoExpandSession)
    return {
      allPayments,
      allEvents
    }
  }

  getTotaliserData (thisState, allPayments) {
    const totaliserData = []
    allPayments
      .filter(ap => {
        const tagsCompletedAlready = ap.customDataPublic.readFrom('tagsCompletedAlready') || []
        if (thisState.completeByTag && thisState.filterByProductTags.size > 0) {
          // console.warn('[DebugLater4] 4 tagsCompletedAlready', tagsCompletedAlready)
          return !thisState.filterByProductTags.toArray().every(_ => tagsCompletedAlready.includes(_))
        } else {
          return ap.newStatusDone === false
        }
      })
      .forEach(ap => {
        ap.rawCart
          .filter(ci => {
            if (thisState.filterByProductTags.size === 0) {
              return true
            }
            return ci.product ? ci.product.tags.toArray().some(pTag => thisState.filterByProductTags.has(pTag)) : true
          })
          .forEach(ci => {
            const foundInTd = totaliserData.find(td => td.productId === ci.productId)
            if (foundInTd) {
              foundInTd.quantity += ci.quantity
            } else {
              totaliserData.push({
                productName: ci.productName,
                productId: ci.productId,
                quantity: ci.quantity,
                questions: []
              })
            }
          })
      })
    return totaliserData
  }

  async getCards (veryFreshState, immediateState) {
    log('[Route-view-stickyretail] [getCards] 1', { immediateState, veryFreshState, thisIsWorking: this.isWorking })
    immediateState && this.setState(immediateState)
    if (this.isWorking) {
      veryFreshState && this.setState(veryFreshState)
      return
    }
    this.isWorking = true

    try {
      const thisState = { ...this.state, ...(veryFreshState || {}), ...(immediateState || {}) }
      const { showTotaliser } = thisState
      const { allPayments, allEvents } = await this.getCardsData(thisState)

      log('[Route-view-stickyretail] [getCards] 2', { allPayments, allEvents })

      const cards = [
        ...allEvents.map(entity => ({
          holder: Cards.EVENT_TO_DO,
          entity,
          time: entity.time,
          getTimeDone: () => entity.getTimeDone(),
          id: entity.id
        })),
        ...allPayments.map(entity => ({
          holder: Cards.Payment,
          entity,
          time: entity.time,
          getTimeDone: () => entity.getTimeDone(),
          id: entity.id
        }))
      ]
      const howManyCardsNow = cards.length

      const { howManyCardsBefore } = this.state
      const isThereANewCard = typeof howManyCardsBefore === 'number' && howManyCardsNow > howManyCardsBefore
      if (isThereANewCard) {
        this.newCard()
      }

      const things = await window.sticky.things.getAll()
      const applications = await window.sticky.applications.getAll()

      let finalState = {
        cards,
        things,
        applications,
        howManyCardsBefore: howManyCardsNow,
        ...veryFreshState,
        ...immediateState
      }

      if (showTotaliser) {
        finalState = {
          ...finalState,
          totaliserData: showTotaliser ? this.getTotaliserData({ ...this.state, ...finalState}, allPayments) : undefined,
        }
      }

      this.setState(
        finalState,
        () => {
          this.isWorking = false
        }
      )
    } catch (e) {
      window.sticky.applications.blocks.showError(`The latest cards couldn't be fetched.\n\nDo you have an internet connection?\n\n(${e.message})`, true)
      error('[Route-view-stickyretail] [getCards] 3', e)
    } finally {
      this.isWorking = false
    }
  }

  newCard() {
    this.beeper.beep()
  }

  async componentDidMount() {
    document.querySelector('main').style.maxWidth = 'none'
    document.querySelector('.component--navbar').style.display = 'none'

    const { user, userPreferences } = this.props

    if (!user.federatedUserCan('live-payments')) {
      return window.sticky.applications.blocks.showError('You do not have permission to see live payments.')
    }

    this.beeper = beep(userPreferences.toDoNewCardSound || 'beep')

    this.subscriptions = [
      subscribe(
        'CHOOSE_FEDERATED_USER_GOOD',
        ({ why, entity, federatedUser }) => {
          why === 'stickyretail' && (() => {
            const { currentFederatedUser: oldCurrentFederatedUser } = this.state
            if (!oldCurrentFederatedUser) {
              this.isWorking = false
              this.getCards({ currentFederatedUser: federatedUser })
              this.refreshTimer = DO_REFRESH && setInterval(
                this.getCards,
                REFRESH_INTERVAL
              )
            } else {
              const state = {
                currentFederatedUser: federatedUser
              }
              this.setState(state)
            }
          })()
          why === 'stickyretail--changeFederatedUser--Payment' && (() => {
            entity.lastFederatedUserId = federatedUser.id
            dispatch('LOADING')
            window.sticky.pay.save(entity, false)
              .then(() => {
                dispatch('STOP_LOADING')
                this.forceUpdate()
              })
          })()
          why === 'stickyretail--changeFederatedUser--EVENT_TO_DO' && (() => {
            entity.federatedUserId = federatedUser.id
            dispatch('LOADING')
            window.sticky.session.events.save(entity)
              .then(() => {
                dispatch('STOP_LOADING')
                this.forceUpdate()
              })
          })()
        }
      ),
      subscribe(
        'CHOOSE_APPLICATIONS_GOOD',
        ({ why, applications }) => {
          why === 'stickyretail' && (() => {
            lsSave('filteringByApplicationId', applications.length > 0 ? applications[0].id : undefined)
            this.isWorking = false
            this.getCards({ filteringByApplication: applications[0] })
          })()
        }
      ),
      subscribe(
        'CHOOSE_THING_GOOD',
        ({ thing: filteringByThing, why }) => {
          why === 'stickyretail' && (() => {
            lsSave('filteringByThingId', filteringByThing ? filteringByThing.id : undefined)
            this.isWorking = false
            this.getCards({ filteringByThing })
          })()
        }
      ),
      subscribe(
        'SURE_DELETE_GOOD',
        ({ why }) => {
          why === 'stickyretail--delete-events--TO_DO' && (() => {
            const { filteringByApplication, filteringByThing } = this.state
            dispatch(
              'TRIGGER',
              {
                trigger: 'delete-events-payments',
                body: {
                  eventType: 'TO_DO',
                  applicationId: filteringByApplication ? filteringByApplication.id : undefined,
                  thingId: filteringByThing ? filteringByThing.id : undefined
                },
                showMessage: true
              }
            )
            this.isWorking = false
            this.getCards()
          })()
        }
      ),
      subscribe(
        'TRIGGER_GOOD',
        ({ trigger }) => {
          trigger === 'delete-events-payments' && (() => {
            this.isWorking = false
            this.getCards()
          })()
        }
      ),
      subscribe(
        'CHOOSE_TAGS_GOOD',
        ({ why, entity, tags }) => {
          why === 'stickyretail--Payment--tags' && (async () => {
            this.isWorking = true
            entity.tags = tags
            Cards.Payment.patch(entity, false, ['tags'])
              .then(() => {
                this.isWorking = false
                this.forceUpdate()
              })
          })()
          why === 'stickyretail--product-tags' && (() => {
            lsSave('filterByProductTags', tags.toArray())
            this.isWorking = false
            this.getCards({
              filterByProductTags: tags
            })
          })()
        }
      )
    ]
  }

  async componentWillUnmount() {
    this.beeper.unbeep()
    clearInterval(this.refreshTimer)
    this.subscriptions && this.subscriptions.forEach(s => s())
  }

  switchUser (canBeClosed) {
    dispatch('CHOOSE_FEDERATED_USER', { why: 'stickyretail', canBeClosed })
  }

  render () {
    const { user, userPreferences, autoUi } = this.props
    const {
      cards,
      things,
      applications,
      filteringByApplication,
      filteringByThing,
      filteringByFederatedUser,
      onlyShowEvents,
      onlyShowPayments,
      hideCompleted,
      completeByTag,
      autoExpandSession,
      filterByProductTags,
      showingDummyModal,
      federatedUsers,
      currentFederatedUser,
      overrideApplicationId,
      totaliserData,
      showTotaliser
    } = this.state
    const cardRdic = {
      user,
      federatedUsers,
      things,
      applications
    }
    log('[Route-view-stickyretail] [render]', { cards, cardRdic })
    let cardsNotDone = Array.isArray(cards) ? cards.filter(p => !p.holder.isDone(p.entity, this.state)) : []
    let cardsDone = hideCompleted ? [] : (Array.isArray(cards) ? cards.filter(p => p.holder.isDone(p.entity, this.state)) : [])

    if ([false, undefined].includes(userPreferences.toDoEVENT_TO_DOFirst)) {
      cardsNotDone = cardsNotDone
        .sort((a, b) => {
          return a.time - b.time
        })
      cardsDone = cardsDone
        .sort((a, b) => {
          if (b.getTimeDone() < a.getTimeDone()) return -1
          if (b.getTimeDone() > a.getTimeDone()) return 1
          return 0
        })
    }

    // const filteringByThingIndex = filteringByThing ? things.indexOf(filteringByThing) : undefined

    const cardMaps = [
      ...cardsNotDone,
      ...cardsDone
    ]
      .map(p => {
        const { Component, doAction, autoExpandedSession } = p.holder
        return (
          <Component
            key={p.id}
            user={user}
            userPreferences={userPreferences}
            autoExpandedSession={autoExpandedSession}
            rdic={cardRdic}
            isDone={p.holder.isDone(p.entity, this.state)}
            currentFederatedUser={currentFederatedUser}
            filterByProductTags={filterByProductTags}
            onAction={(...args) => {
              this.isWorking = true
              doAction(this.state, p.entity, ...args)
                .then((whatToDo) => {
                  this.isWorking = false
                  whatToDo === 'forceUpdate' && this.forceUpdate()
                  whatToDo === 'getCards' && this.getCards()
                })
            }}
            entity={p.entity}
          />
        )
      })

    return (
      <StyledRoute>
        <CustomHelmet
          title='Live payments'
        />
        <div className='controls'>
          {autoUi.includes('backToYeetYeet') && <LinkButton
            to='/sticky-pay'
            sameTab
            backgroundColor='transparent'
          >
            ← Exit
          </LinkButton>}
          {currentFederatedUser && (
            <UserCircle fixedWidthHeight={2.5} onClick={() => this.switchUser(true)} name={currentFederatedUser.name} color={currentFederatedUser.color} photoUrl={currentFederatedUser.photoUrl} />
          )}
          {[true, undefined].includes(userPreferences.toDoFilterByApplication) && applications && applications.length > 0 && (
            <Button
              icon={filteringByApplication && filteringByApplication.baseIcon}
              InlineIcon={!filteringByApplication ? dashboardIcons.application : undefined}
              onClick={() => {
                dispatch('CHOOSE_APPLICATIONS', { why: 'stickyretail', allowNone: '(All)' })
              }}
              disabled={overrideApplicationId}
            >
              {overrideApplicationId && 'Filtering by specific flow'}
              {overrideApplicationId ? '' : (filteringByApplication ? filteringByApplication.name : `[v${window.sticky.version}] Filter by flow`)}
            </Button>
          )}
          {[true, undefined].includes(userPreferences.toDoFilterByApplication) && <Button
            className='choose-thing'
            icon={filteringByThing && filteringByThing.designUrl}
            InlineIcon={!filteringByThing ? dashboardIcons.thing : undefined}
            onClick={() => {
              this.isWorking = true
              ;(async () => {
                dispatch('LOADING')
                const cardsData = await this.getCardsData({ ...this.state, filteringByThing: undefined })
                const highlightedThingIds = Array.from(new Set([
                  ...cardsData.allEvents.filter(_ => _.customData.readFrom('newStatusDone') !== true).map(_ => _.thingId),
                  ...cardsData.allPayments.filter(_ => _.newStatusDone !== true).map(_ => _.thingId)
                ]))
                dispatch('STOP_LOADING')
                dispatch('CHOOSE_THING', { why: 'stickyretail', allowNone: '(All)', highlightedThingIds })
              })()
            }}
          >
            {filteringByThing ? filteringByThing.name : 'Filter by sticky'}
          </Button>}
          <Button
            InlineIcon={dashboardIcons.product}
            onClick={() => dispatch(
              'CHOOSE_TAGS',
              {
                why: 'stickyretail--product-tags',
                tags: filterByProductTags.clone()
              }
            )}
          >
            {!filterByProductTags.hasAny ? 'Filter by product labels' : `Filtering by ${filterByProductTags.size} label${filterByProductTags.size !== 1 ? 's' : ''}`}
          </Button>
          <Button
            InlineIcon={!showTotaliser ? dashboardIcons.events : dashboardIcons.void}
            onClick={() => {
              ;(async () => {
                try {
                  this.isWorking = true
                  if (!showTotaliser) {
                    const { allPayments } = await this.getCardsData(this.state)
                    // TURN ON
                    this.getCards(
                      { showTotaliser: !showTotaliser },
                      { showTotaliser: !showTotaliser, totaliserData: !showTotaliser ? this.getTotaliserData(this.state, allPayments) : undefined }
                    )
                  } else {
                    // TURN OFF
                    this.getCards(
                      { showTotaliser: false, totaliserData: undefined },
                      { showTotaliser: false, totaliserData: undefined }
                    )
                  }
                } catch (e) {
                  // console.warn('[DebugLater4] totaliser on/off', e)
                } finally {
                  this.isWorking = false
                }
              })()
            }}
          >
            {!showTotaliser ? 'Totaliser' : 'Hide totaliser'}
          </Button>
          {[true, undefined].includes(userPreferences.toDoFilterByApplication) && <Button
            InlineIcon={dashboardIcons.dotDotDot}
            listItems={
              [
                {
                  id: 'delete-events',
                  name: 'Clear',
                  InlineIcon: dashboardIcons.void,
                  onChoose: () => {
                    dispatch('SURE_DELETE', { hint: 'Are you sure?', why: 'stickyretail--delete-events--TO_DO' })
                  }
                },
                currentFederatedUser && {
                  id: 'filteringByFederatedUser',
                  name: 'Only mine',
                  icon: filteringByFederatedUser ? icons.generic.check : icons.generic.uncheck,
                  onChoose: () => {
                    lsSave('filteringByFederatedUser', !filteringByFederatedUser)
                    this.isWorking = false
                    this.getCards({ filteringByFederatedUser: !filteringByFederatedUser })
                  }
                },
                {
                  id: 'onlyShowEvents',
                  name: 'Only to dos',
                  icon: onlyShowEvents ? icons.generic.check : icons.generic.uncheck,
                  onChoose: () => {
                    lsSave('onlyShowEvents', !onlyShowEvents)
                    this.isWorking = false
                    this.getCards({ onlyShowEvents: !onlyShowEvents })
                  }
                },
                {
                  id: 'onlyShowPayments',
                  name: 'Only payments',
                  icon: onlyShowPayments ? icons.generic.check : icons.generic.uncheck,
                  onChoose: () => {
                    lsSave('onlyShowPayments', !onlyShowPayments)
                    this.isWorking = false
                    this.getCards({ onlyShowPayments: !onlyShowPayments })
                  }
                },
                {
                  id: 'hideCompleted',
                  name: 'Hide completed',
                  icon: hideCompleted ? icons.generic.check : icons.generic.uncheck,
                  onChoose: () => {
                    lsSave('hideCompleted', !hideCompleted)
                    this.isWorking = false
                    this.getCards({ hideCompleted: !hideCompleted })
                  }
                },
                {
                  id: 'completeByTag',
                  name: 'Complete by label',
                  icon: completeByTag ? icons.generic.check : icons.generic.uncheck,
                  onChoose: () => {
                    lsSave('completeByTag', !completeByTag)
                    this.isWorking = false
                    this.getCards({ completeByTag: !completeByTag })
                  }
                },
                {
                  id: 'autoExpandSession',
                  name: 'Show consumer',
                  icon: autoExpandSession ? icons.generic.check : icons.generic.uncheck,
                  onChoose: () => {
                    lsSave('autoExpandSession', !autoExpandSession)
                    this.isWorking = false
                    this.getCards({ autoExpandSession: !autoExpandSession })
                  }
                }
              ]
                .filter(e => e)
            }
            listItemsWidth={218}
          />}
          {/* {filteringByThing && (
            <Button
              title='Previous sticky'
              disabled={filteringByThingIndex === 0}
              inlineIconString={window.sticky.internals.icons.get('arrowLeft')}
              onClick={() => {
                this.isWorking = false
                this.getCards({ filteringByThing: things[filteringByThingIndex - 1] })
              }}
            />
          )}
          {filteringByThing && (
            <Button
              title='Next sticky'
              disabled={filteringByThingIndex >= things.length - 1}
              inlineIconString={window.sticky.internals.icons.get('arrowRight')}
              onClick={() => {
                this.isWorking = false
                this.getCards({ filteringByThing: things[filteringByThingIndex + 1] })
              }}
            />
          )} */}
          {cards && cardsNotDone.length > 0 && <><strong className='big openbox--blinking'>{cardsNotDone.length} to do</strong></>}
        </div>
        {totaliserData && (
          <section
            className='totaliser-data'
          >
            <H2>Totaliser</H2>
            {totaliserData.length > 0 && <CoolCart>
              <EposCart
                cart={totaliserData}
                canVoid={false}
                showPrices={false}
              />
            </CoolCart>}
            <Button
              InlineIcon={dashboardIcons.void}
              backgroundColor='black'
              onClick={() => {
                this.getCards(
                  { showTotaliser: false, totaliserData: undefined },
                  { showTotaliser: false, totaliserData: undefined }
                )
              }}
            >
              Hide
            </Button>
            {totaliserData.length === 0 && <p>There is nothing to complete.</p>}
          </section>
        )}
        {showingDummyModal && (
          <Modal
            className='beep-yes-no'
            canBeClosed={false}
          >
            <p>Beep when there's a new card?</p>
            <div className='buttons'>
              <Button
                icon={icons.inverted.check}
                onClick={() => {
                  this.beeper.start()
                  this.abstractedPostBeepChoice()
                }}
              >
                Beep!
              </Button>
              <Button
                isSecondary
                onClick={() => {
                  this.abstractedPostBeepChoice()
                }}
              >
                No
              </Button>
            </div>
          </Modal>
        )}
        <div className={classnames('to-dos', { 'show-totaliser': showTotaliser })}>
          {!cards && <Loading />}
          {cards && cardMaps}
        </div>
      </StyledRoute>
    )
  }

  async abstractedPostBeepChoice () {
    dispatch('LOADING')
    const federatedUsers = await window.sticky.users.federated.getAll()
    const forSetState = {
      federatedUsers,
      showingDummyModal: false
    }
    const filteringByApplicationId = lsLoad('filteringByApplicationId')
    if (filteringByApplicationId) {
      try {
        forSetState.filteringByApplication = await window.sticky.applications.get(filteringByApplicationId)
      } catch ({}) {}
    }
    const filteringByThingId = lsLoad('filteringByThingId')
    if (filteringByThingId) {
      try {
        forSetState.filteringByThing = await window.sticky.things.get(filteringByThingId)
      } catch ({}) {}
    }
    this.setState(
      forSetState,
      () => {
        dispatch('STOP_LOADING')
        federatedUsers && federatedUsers.length > 0 && this.switchUser()
      }
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  userPreferences: PropTypes.object,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
