import React, { Component, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { icons, CustomHelmet, LinkButton } from '@openbox-app-shared'
import dashboardIcons from '../icons'

import Box from '../components/Box'
import MainFrame from '../components/MainFrame'
import User from '../components/User'
import TabBar from '../components/TabBar'
import H1 from '../components/H1'
import Reports from '../components/Reports'
import EventExplorer from '../components/Events/EventExplorer'
import AbstractedPayments from '../components/AbstractedPayments'
import _ from '../_'

import { log } from '../log'
import HeaderBar from '../components/HeaderBar'

function AllRenderChild ({ user }) {
  const [payments, setPayments] = useState()
  useEffect(() => {
    async function getPayments (force) {
      const payments = {
        refundedAt: await (async () => {
          window.sticky.pay.setQuery(['refundedAt=!!!null', 'orderBy=refunded_at', user.federatedUser && user.federatedUserCan('payments-own') && `lastFederatedUserId=${user.federatedUser.id}`].filter(_ => _).join('&'))
          const toReturn = [...(await window.sticky.pay.getAll(force, false))]
          window.sticky.pay.resetQuery()
          return toReturn
        })(),
        sessionFailedAt: await (async () => {
          window.sticky.pay.setQuery(['sessionFailedAt=!!!null', user.federatedUser && user.federatedUserCan('payments-own') && `lastFederatedUserId=${user.federatedUser.id}`].filter(_ => _).join('&'))
          const toReturn = [...(await window.sticky.pay.getAll(force))].reverse()
          window.sticky.pay.resetQuery()
          return toReturn
        })(),
        sessionFailedAtConnection: await (async () => {
          window.sticky.pay.setQuery(['sessionFailedAtConnection=!!!null', user.federatedUser && user.federatedUserCan('payments-own') && `lastFederatedUserId=${user.federatedUser.id}`].filter(_ => _).join('&'))
          const toReturn = [...(await window.sticky.pay.getAll(force))].reverse()
          window.sticky.pay.resetQuery()
          return toReturn
        })()
      }
      setPayments(payments)
    }
    !payments && getPayments(false)
  })

  const tabs = [
    {
      id: 'Success',
      name: 'Success',
      inlineIcon: dashboardIcons.check,
      child: (
        <EventExplorer
          queryId='user-session--session||type'
          queryObject={{ type: 'SESSION_CART_PAY' }}
          defaultValue='table'
          currentFederatedUserId={user.federatedUser ? user.federatedUser.id : undefined}
        />
      )
    },
    {
      id: 'Failure',
      name: 'Failure',
      inlineIcon: dashboardIcons.void,
      child: (
        <AbstractedPayments
          user={user}
          payments={payments && payments.sessionFailedAt}
        />
      )
    },
    user.connections.length > 0 && {
      id: 'Failure due to a connection',
      name: 'Failure due to a connection',
      inlineIcon: dashboardIcons.void,
      child: (
        <AbstractedPayments
          user={user}
          payments={payments && payments.sessionFailedAtConnection}
        />
      )
    },
    {
      id: 'Refunded',
      name: 'Refunded',
      inlineIcon: dashboardIcons.goBack,
      child: (
        <AbstractedPayments
          user={user}
          payments={payments && payments.refundedAt}
        />
      )
    }
  ]
    .filter(e => e)

  return (
    <TabBar
      tabs={tabs}
    />
  )
}
AllRenderChild.propTypes = {
  user: PropTypes.object
}

const StyledRoute = styled.div`
  padding: 1rem;
  .component--event-explorer {
    margin-top: 1rem;
  }
  .go-back button {
    margin-bottom: 1rem;
  }
  .report-container > .component--box + .component--box {
    margin-top: 1rem;
  }
  .component--abstracted-payments {
    margin-top: 1rem;
  }
  .component--main-frame > .content > .component--box > .component--tabbar > .component--bar {
    display: none;
  }
`

export default class Route extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    const { user } = this.props
    if (!user.federatedUserCan('payments')) {
      window.sticky.applications.blocks.showMessage('Sorry; you do not have permission to see payments.', undefined, false, false)
      return
    }
  }

  render () {
    const { user, match } = this.props
    log('[Route-reports] [render]', { user, match })

    const headerBar = ({
      '/analytics/consumers': {
        text: 'People',
        Icon: dashboardIcons.teamMember
      },
      '/analytics/home': {
        text: 'Analytics',
        Icon: dashboardIcons.report
      },
      '/analytics/payments': {
        text: 'Payments',
        Icon: dashboardIcons.payment
      },
      '/analytics/activity': {
        text: 'Activity',
        Icon: dashboardIcons.events
      },
      '/analytics/check-in': {
        text: 'Check ins',
        Icon: dashboardIcons.location
      }
    })[match.url]

    return (
      <StyledRoute>
        <CustomHelmet title='Analytics' />
        <MainFrame
          user={user}
          autoUi={this.props.autoUi}
          aside={<>
            <User user={user} whichPart={match.path} autoUi={this.props.autoUi} />
          </>}
          main={
            <>
              <HeaderBar {...headerBar} user={user} />
              {user.federatedUserCan('payments') && <Box>
                <TabBar
                  selectedTab={match.params.reportId}
                  tabs={[
                    user.federatedUserCan('master') && {
                      id: 'home',
                      name: 'Analytics',
                      inlineIcon: dashboardIcons.report,
                      to: '/analytics/home',
                      child: ({ queryParams }) => {
                        const SUB_ENTITIES = {
                          'stickies': {
                            report: {
                              id: 'things',
                              query: { thingId: queryParams.subEntityId  },
                            },
                            eventExplorer: {
                              queryId: 'v2-activity-thing',
                              queryObject: { thingId: queryParams.subEntityId }
                            },
                            tabText: 'Sticky activity',
                            goBackText: 'Choose another sticky'
                          },
                          'flows': {
                            report: {
                              id: 'applications',
                              query: { applicationId: queryParams.subEntityId  }
                            },
                            eventExplorer: {
                              queryId: 'v2-activity-application',
                              queryObject: { applicationId: queryParams.subEntityId  }
                            },
                            tabText: 'Flow activity',
                            goBackText: 'Choose another flow'
                          }
                        }
                        const reportArgs = queryParams.subEntity ? SUB_ENTITIES[queryParams.subEntity] : { report: { id: 'thingsApplications', query: {} } }
                        const showAsTabBar = [reportArgs.report, reportArgs.eventExplorer].every(_ => _)
                        return (
                          <div className='report-container'>
                            {user.federatedUserCan('payments') && !showAsTabBar && <Box>
                              <H1>Payments</H1>
                              <Reports user={user} reportId='paymentsLight' />
                            </Box>}
                            {reportArgs.goBackText && (
                              <LinkButton
                                to='/analytics/home'
                                isSecondary
                                sameTab
                                className='go-back'
                              >
                                ← {reportArgs.goBackText}
                              </LinkButton>
                            )}
                            {showAsTabBar && <TabBar
                              tabs={[
                                {
                                  id: 'report',
                                  inlineIcon: dashboardIcons.report,
                                  name: 'Summary',
                                  child: (
                                    <Reports user={user} reportId={reportArgs.report.id} query={reportArgs.report.query} />
                                  )
                                },
                                {
                                  id: 'events',
                                  inlineIcon: dashboardIcons.events,
                                  name: SUB_ENTITIES[queryParams.subEntity].tabText,
                                  child: (
                                    <EventExplorer
                                      queryId={reportArgs.eventExplorer.queryId}
                                      queryObject={reportArgs.eventExplorer.queryObject}
                                      filterByApplication={false}
                                      filterByTime={false}
                                      currentFederatedUserId={user.federatedUser ? user.federatedUser.id : undefined}
                                    />
                                  )
                                }
                              ]
                                .filter(e => e)
                              }
                            />}
                            {!showAsTabBar && <Box>
                              <H1>Interactions</H1>
                              <Reports user={user} reportId={reportArgs.report.id} query={reportArgs.report.query} />
                            </Box>}
                          </div>
                        )
                      }
                    },
                    user.federatedUserCan('master') && {
                      id: 'consumers',
                      name: 'People',
                      inlineIcon: dashboardIcons.teamMember,
                      to: '/analytics/consumers',
                      child: () => (
                        <div className='report-container'>
                          <CustomHelmet title='People' />
                          <Reports showTimePeriodChooser={false} user={user} reportId='userSessions' />
                        </div>
                      )
                    },
                    user.federatedUserCan('payments') && {
                      id: 'payments',
                      name: 'Payments',
                      inlineIcon: dashboardIcons.payment,
                      to: '/analytics/payments',
                      child: () => (
                        <div className='report-container'>
                          <CustomHelmet title='Payments' />
                          <div className='all'>
                            <AllRenderChild user={user} />
                          </div>
                        </div>
                      )
                    },
                    user.federatedUserCan('master') && {
                      id: 'activity',
                      name: 'Activity',
                      inlineIcon: dashboardIcons.events,
                      to: '/analytics/activity',
                      child: () => (
                        <div className='report-container'>
                          <CustomHelmet title='Activity' />
                          <EventExplorer
                            queryId='user-session--session||type'
                            queryObject={{}}
                            currentFederatedUserId={user.federatedUser ? user.federatedUser.id : undefined}
                          />
                        </div>
                      )
                    },
                    user.federatedUserCan('master') && {
                      id: 'check-in',
                      name: 'Check ins',
                      inlineIcon: dashboardIcons.location,
                      to: '/analytics/check-in',
                      child: () => (
                        <div className='report-container'>
                          <CustomHelmet title='Check ins' />
                          {/* you can put pipes (||) in the type key below, e.g. ABC||DEF */}
                          <Reports user={user} canChooseApplicationId reportId='events' query={{ type: 'CHECK_IN||CHECK_OUT' }} timeWiggle={15 * 60} />
                        </div>
                      )
                    }
                  ]
                    .filter(e => e)
                    .map(e => ({ ...e, child: e.child({ queryParams: match.params }) }))
                  }
                />
              </Box>}
            </>
          }
        />
      </StyledRoute>
    )
  }
}

Route.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
