import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import GenericModal from '../../../components/modals/generic'
import { Loading } from '@openbox-app-shared'
import { dispatch } from '../../../redux'
import { log } from '../../../log'


function formatDate(dateString, { withTime } = { withTime: false }) {
  if (!dateString) return ''
  const date = new Date(dateString)

  // Choose different formatting for created_at vs due_date
  if (withTime) {
    // Example: "Feb 20, 2025, 10:00 AM GMT"
    return date.toLocaleString(undefined, {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      timeZoneName: 'short',
    })
  } else {
    // Example: "March 1, 2025"
    return date.toLocaleString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }
}

// Helper function to format the invoice month (YYYY-MM to Month YYYY)
function formatInvoiceMonth(monthString) {
  if (!monthString) return ''

  const [year, month] = monthString.split('-')
  const date = new Date(year, parseInt(month) - 1, 1)

  return date.toLocaleString(undefined, {
    year: 'numeric',
    month: 'long',
  })
}

/* ==================== STYLES ==================== */

const StyledModal = styled.div`
  .component--modal {
    max-width: 52rem;
    padding: 1rem;
    background: #f5f7fa; /* subtle background */
    border-radius: 8px;
  }

  h2 {
    margin-bottom: 2rem;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 1.2rem;
  }

  .cards-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }
`

/**
 * Each invoice is displayed in this refined card style.
 */
const StyledCard = styled.div`
  background: linear-gradient(135deg, #ffffff, #fafafa);
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 1.5rem 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  .card-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 0.75rem;
  }

  .invoice-title {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .paid-badge {
    padding: 0.35rem 0.85rem;
    border-radius: 50px;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    &.paid-yes {
      background-color: #3cb043; /* green */
    }
    &.paid-no {
      background-color: #dc143c; /* crimson */
    }
  }

  .card-field {
    display: flex;
    margin: 0.6rem 0;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 0.35rem;

    .label {
      color: #666;
      font-weight: 500;
      min-width: 160px;
    }

    .value {
      color: #333;
      margin-left: 1rem;
      word-break: break-all;
    }
  }

  &:hover {
    box-shadow: 0 5px 14px rgba(0, 0, 0, 0.12);
    transform: translateY(-3px);
  }
`

/* ==================== COMPONENTS ==================== */

// eslint-disable-next-line react/prop-types
function InvoicesModal({ onClose, onDone, otherUser }) {
  const [invoices, setInvoices] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let isMounted = true

    async function fetchInvoices() {
      try {
        console.log(otherUser)
        // eslint-disable-next-line react/prop-types
        const apiInvoices = await window.sticky.invoice.getAll(otherUser.privateKey)
        if (isMounted) setInvoices(apiInvoices)
      } catch (err) {
        console.error('Error fetching invoices:', err)
        if (isMounted) setInvoices([])
      } finally {
        if (isMounted) setIsLoading(false)
      }
    }

    fetchInvoices()
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react/prop-types
  }, [otherUser.privateKey])

  if (isLoading) {
    return (
      <StyledModal>
        <GenericModal onClose={onClose}>
          <Loading />
        </GenericModal>
      </StyledModal>
    )
  }

  return (
    <StyledModal>
      <GenericModal
        onGood={() => {
          onDone(invoices)
        }}
        onClose={onClose}
      >
        <h2>Invoices</h2>

        <div className="cards-container">
          {invoices.length === 0 ? (
            <div style={{ textAlign: 'center', padding: '2rem', color: '#666' }}>
                  No invoices found for this user
            </div>
          ) : (
            invoices.map((inv) => (
              <StyledCard key={inv.id}>
                {/* Card Header */}
                <div className="card-header">
                  <div className="invoice-title">#{inv.invoiceNumber}</div>
                  <div
                    className={`paid-badge ${
                      inv.paid ? 'paid-yes' : 'paid-no'
                    }`}
                  >
                    {inv.paid ? 'Paid' : 'Unpaid'}
                  </div>
                </div>

                <div className="card-field">
                  <span className="label">Invoice ID:</span>
                  <span className="value">{inv.id}</span>
                </div>

                <div className="card-field">
                  <span className="label">Invoice Month:</span>
                  <span className="value">{formatInvoiceMonth(inv.invoiceMonth)}</span>
                </div>

                <div className="card-field">
                  <span className="label">Created On:</span>
                  <span className="value">{formatDate(inv.createdAt, { withTime: true })}</span>
                </div>

                <div className="card-field">
                  <span className="label">Due On:</span>
                  <span className="value">{formatDate(inv.dueDate)}</span>
                </div>
              </StyledCard>
            ))
          )}
        </div>
      </GenericModal>
    </StyledModal>
  )
}

/**
 * The exported default with `trigger` / `render` for your existing structure.
 */
export default {
  trigger: ({ setState, otherUser }) => {
    setState({ SHOW_INVOICES: { otherUser } })
  },

  render: function SHOW_INVOICES({ state, setState }) {
    const { SHOW_INVOICES } = state
    if (!SHOW_INVOICES) return null

    const { otherUser } = SHOW_INVOICES

    const onDone = (invoices) => {
      log('Finished viewing invoices. Possibly saving data...', { invoices })
      dispatch('INVOICES_VIEWED', { invoices })
      setState({ SHOW_INVOICES: undefined })
    }

    const onClose = () => {
      setState({ SHOW_INVOICES: undefined })
    }

    return <InvoicesModal otherUser={otherUser} onDone={onDone} onClose={onClose} />
  },
}