/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import dashboardIcons from '../../icons'

import { Input, Button, ColourPicker, CustomHelmet } from '@openbox-app-shared'
import { Tag } from '../../components/TagChooser'

const StyledComponent = styled.div`
  padding-top: 1rem;
  padding-bottom: 5rem;
  .component--button.add {
    margin-bottom: 1rem;
  }
  .tag-wrapper {
    > * {
      display: inline-block;
      vertical-align: top;
    }
    > .component--input {
      margin-left: 0.5rem;
      input {
        height: 2rem;
        font-size: 80%;
      }
    }
    .component--button.delete {
      width: 2rem;
      height: 2rem;
      margin-left: 0.5rem;
      padding: 0.25rem;
    }
  }
  .tag-wrapper + .tag-wrapper {
    margin-top: 1rem;
  }
  .tw-which-colour {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0.25rem;
    margin-left: 0.25rem;
    padding: 0;
    border-radius: 4px;
    .component--button-dropdown-container {
      left: 38px !important;
      top: -6px !important;
    }
  }
`

function TagWrapper ({ tag, onChange }) {
  let howToCollapse
  return (
    <div className='tag-wrapper'>
      <Tag
        tag={{
          ...tag,
          color: '#f0f1f3',
          name: <Button
            className='tw-which-colour'
            backgroundColor={tag.color}
            dropdownWidth={119}
            onReady={({ doCollapse }) => {
              howToCollapse = doCollapse
            }}
            DropdownElement={
              <ColourPicker
                currentColour={tag.color}
                colours={[
                  '#feca57', '#ff9f43', '#ff6b6b', '#ee5253',
                  '#FF00FF', '#5f27cd', '#0abde3', '#4b7bec',
                  '#2ed573', '#009432', '#57606f', '#2f3542'
                ]}
                columns={4}
                cellSize={1.5}
                label='Color'
                onChoose={v => {
                  tag.color = v
                  onChange('generic')
                  howToCollapse && howToCollapse()
                }}
              />
            }
          />
        }}
        has
        can={false}
      />
      <Input
        value={tag.name}
        onChange={v => {
          tag.name = v
          onChange('generic')
        }}
      />
      <Button
        title='Delete'
        backgroundColor='#ff3838'
        className='delete'
        InlineIcon={dashboardIcons.delete}
        onClick={() =>  onChange('delete', tag.id)}
      />
    </div>
  )
}

const tab = {
  id: 'labels',
  name: () => 'Labels',
  inlineIcon: dashboardIcons.tags,
  to: (context) => '/my/account/labels',
  child: ({ context, dbfProps }) => {
    const { user } = context.props
    return (
      <StyledComponent>
        <CustomHelmet title={`Labels | Account | ${user.name}`} />
        <Button
          className='add'
          InlineIcon={dashboardIcons.add}
          onClick={() => {
            user.tags.set(
              [
                ...user.tags.get(),
                {
                  id: window.sticky.uuid(),
                  name: 'Label',
                  color: '#1A1F35'
                }
              ]
            )
            dbfProps.onChange()
          }}
        >
          Label
        </Button>
        {user.tags.get().map(tag => <TagWrapper
          key={tag.id}
          tag={tag}
          onChange={(method, ...args) => {
            ({
              'generic': dbfProps.onChange,
              'delete': id => {
                user.tags.set(
                  user.tags.get().filter(t => t.id !== id)
                )
                dbfProps.onChange()
              }
            })[method](...args)
          }}
        />)}
      </StyledComponent>
    )
  }
}

export default tab
