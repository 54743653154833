/* eslint-disable react/prop-types */
/* get fucked */

import React, { PureComponent } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../Button'
import Banner from '../Banner'

import Stage1 from './Stage1'
import T from '../T'

import cardVisa from '../../images/card--visa.svg'
import cardMastercard from '../../images/card--mastercard.svg'
import cardAmex from '../../images/card--amex.svg'
import cardDiscover from '../../images/card--discover.svg'
import cardUnknown from '../../images/card--unknown.svg'

const AUTO_CARD_WAIT_TIME = 1500

function formatNumberRegular (v, data) {
  const newV = v.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
  const matches = newV.match(/\d{4,16}/g)
  const match = matches && matches[0] || ''
  const parts = []
  for (let i = 0; i < match.length; i += 4) {
    parts.push(match.substring(i, i + 4))
  }
  return parts.length > 0 ? ['****', '****', '****', parts[3]].join(' ') : v
}

function cleanNumber (v) {
  if (typeof v !== 'string') {
    return ''
  }
  return v.replace(/[^0-9]+/g, '')
}

const CARD_TYPES = flags => {
  return [
    {
      id: 'visa',
      image: cardVisa,
      regex: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,
      cvvRegex: /^[0-9]{3}$/,
      formatNumber: formatNumberRegular,
      notSupported: !flags.includes('scheme--visa') ? 'Sorry, we do not accept Visa.' : undefined
    },
    {
      id: 'mastercard',
      image: cardMastercard,
      regex: /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/,
      cvvRegex: /^[0-9]{3}$/,
      formatNumber: formatNumberRegular,
      notSupported: !flags.includes('scheme--mastercard') ? 'Sorry, we do not accept Mastercard.' : undefined
    },
    {
      id: 'discover',
      image: cardDiscover,
      regex: /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/,
      cvvRegex: /^[0-9]{3}$/,
      formatNumber: formatNumberRegular,
      notSupported: !flags.includes('scheme--discover') ? 'Sorry, we do not accept Discover.' : undefined
    },
    {
      id: 'american-express',
      image: cardAmex,
      regex: /^(?:3[47][0-9]{13})$/,
      cvvRegex: /^[0-9]{4}$/,
      formatNumber: (v, data) => v.replace(/\b(\d{4})(\d{6})(\d{5})\b/, '$1 $2 $3'),
      notSupported: !flags.includes('scheme--amex') ? 'Sorry, we do not accept American Express.' : undefined
    }
  ]
}

const CARD_TYPE_UNKNOWN = {
  id: 'unknown',
  image: cardUnknown,
  cvvRegex: /^[0-9]{3,4}$/,
  formatNumber: formatNumberRegular
}

const FIELDS = flags => {
  return [
    {
      id: 'name',
      stage: 1,
      prefill: true,
      isValid: (v, data, caresAboutCardName) => {
        if (!caresAboutCardName) {
          return true
        }
        return (typeof v === 'string' && v.length > 0)
      },
      format: v => v.toUpperCase(),
      doSaveApi: true
    },

    {
      id: 'avsAddress',
      stage: 1,
      prefill: true,
      isValid: (v, data, caresAboutCardName, caresAboutCardCvv, doAvs) => {
        if (!doAvs) {
          return true
        }
        return (typeof v === 'string' && v.length > 0)
      },
      doSaveApi: true
    },
    {
      id: 'avsPostcode',
      stage: 1,
      prefill: true,
      isValid: (v, data, caresAboutCardName, caresAboutCardCvv, doAvs) => {
        if (!doAvs) {
          return true
        }
        return (typeof v === 'string' && v.length > 0)
      },
      doSaveApi: true
    },

    {
      id: 'number',
      stage: 1,
      prefill: true,
      isValid: v => {
        console.warn('[Card] [FIELDS->number] [isValid]', { flags, cleanNumber: cleanNumber(v) })
        const foundCardType = CARD_TYPES(flags).find(({ regex }) => cleanNumber(v).match(regex))
        return foundCardType ? !foundCardType.notSupported : false
      },
      format: (v, data) => {
        const cardType = getCardType(flags, data.number)
        return cardType.formatNumber(v, data)
      },
      formatForApi: (v, data) => {
        return cleanNumber(v)
      },
      doSaveApi: true
    },
    {
      id: 'expires',
      stage: 1,
      prefill: true,
      isValid: v => {
        const validSyntax = typeof v === 'string' && v.match(/^\d{2}\/\d{2}$/) ? true : false
        if (!validSyntax) {
          return false
        }
        let [month, year] = v.split('/').map(p => parseInt(p, 10))
        if (month > 12) {
          return false
        }
        const now = window.sticky.dateTime.getDate()
        const nowYear = parseInt(now.getFullYear().toString().slice(2), 10)
        const nowMonth = now.getMonth() + 1
        return (
          ((year * 100) + month) >= ((nowYear * 100) + nowMonth)
        )
      },
      doSaveApi: true
    },
    {
      id: 'cvv',
      stage: 1,
      isValid: (v, data, caresAboutCardName, caresAboutCardCvv) => {
        if (!caresAboutCardCvv) {
          return true
        }
        const cardType = getCardType(flags, data.number)
        return typeof v === 'string' && v.match(cardType.cvvRegex) ? true : false
      },
      doSaveApi: false
    }
  ]
}

const StyledCard = styled.div`
  width: 300px;
  .no-card-image {
    height: 0.75rem;
  }
  .card {
    position: relative;
    width: 100%;
    height: 191px;
    background-repeat: no-repeat;
    background-size: 100% 191px;
    background-position: 0 0;
    .part {
      position: absolute;
      color: white;
      font-family: "Fira code", "Fira Mono", monospace;
    }
    .part.name, .part.expires {
      font-size: 14px;
      letter-spacing: -0.5px;
    }
    .part.name {
      top: 157px; left: 31px;
    }
    .part.expires {
      top: 139px; left: 31px;
    }
    .part.number {
      top: 108px; left: 31px;
      font-size: 20px;
      letter-spacing: 0.5px;
    }
  }
  .component--form, .component--banner {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .buttons {
    margin-top: 1rem;
    text-align: center;
    .component--button {
      display: inline-block;
      margin: 0 auto 0 auto;
      padding: 0.625rem 1.1rem 0.625rem 1.1rem;
      font-size: 110%;
      border-radius: 5px !important;
    }
    .component--button + .component--button {
      margin-left: 1rem;
    }
  }
  .buttons + .buttons {
    margin-top: 1rem;
  }
  .component--button.exit {
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    box-shadow: none;
    border-color: white;
    color: #A9B7C6;
    &:focus {
      border: 2px solid #A9B7C6;
    }
  }
`

const STAGES = [
  {
    Component: Stage1,
    canGoNext: (flags, data, caresAboutCardName, caresAboutCardCvv, doAvs) => {
      return FIELDS(flags).filter(f => f.stage === 1).every(f => f.isValid(data[f.id], data, caresAboutCardName, caresAboutCardCvv, doAvs))
    }
  }
]

async function asyncSetState (setState, state) {
  return new Promise(resolve => setState(state, resolve))
}

function getCardType (flags, number) {
  return CARD_TYPES(flags).find(ct => cleanNumber(number).match(ct.regex)) || CARD_TYPE_UNKNOWN
}

export default class Card extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      stageNumber: 0
    }
    this.setState = this.setState.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onDebouncedChange = this.onDebouncedChange.bind(this)
    this.goPrevious = this.goPrevious.bind(this)
    this.goNext = this.goNext.bind(this)
    this.onDone = this.onDone.bind(this)
    this.setIsLoading = this.setIsLoading.bind(this)
  }

  async componentDidMount () {
    const preData = { name: window.sticky.session.getSync().crossUserSector.readFrom('name') }
    const { flags, caresAboutCardName, doAvs, caresAboutCardCvv, hideCardUi, autoCard } = this.props
    const session = await window.sticky.session.get()
    const card = session.card.get()
    const data = { ...preData }
    FIELDS(flags).forEach(f => {
      const v = data[f.id] || card[f.id]
      const isValid = f.isValid(v, card, caresAboutCardName, caresAboutCardCvv, doAvs)
      console.warn('[Card] [componentDidMount]', { f, isValid })
      data[f.id] = isValid ? v : ''
    })
    const allCanGoNext = STAGES.every(s => s.canGoNext(flags, data, caresAboutCardName, caresAboutCardCvv, doAvs))
    console.warn('[Card] [componentDidMount]', { allCanGoNext, flags, data })

    this.setState(
      { session, data, allCanGoNext, autoCard },
      () => {
        if (autoCard && allCanGoNext) {
          this.stHandler = setTimeout(
            () => {
              this.onDone(false)
            },
            AUTO_CARD_WAIT_TIME
          )
        }
      }
    )
  }

  stopAutoCard () {
    clearTimeout(this.stHandler)
    this.stHandler = undefined
    this.setState({
      autoCard: false
    })
  }

  componentWillUnmount () {
    clearTimeout(this.stHandler)
    this.stHandler = undefined
  }

  onChange (delta, autoDone = false) {
    const { flags, rememberCard } = this.props
    let mergedState = {}
    Object.keys(delta).forEach(k => {
      const { isValid, doSaveApi, formatForApi } = FIELDS(flags).find(f => f.id === k)
      let v = delta[k]
      if (k === 'expires' && v.length === 4) {
        console.warn('[Card] [onChange]->k===expires')
        const transformedValue = v.slice(0, 2) + '/' + v.slice(2)
        if (isValid(transformedValue, {})) {
          v = transformedValue
        }
      }
      mergedState = {
        ...mergedState,
        [k]: v
      }
      const finalSetState = { ...this.state.data, ...mergedState }
      this.setState({ data: finalSetState })
      const isReallyValid = isValid(v, finalSetState)
      console.warn('[Card] [onChange]', { k, v, isReallyValid, doSaveApi, rememberCard })
      if (formatForApi) {
        v = formatForApi(v, mergedState)
      }
      isReallyValid && this.state.session.card.writeTo(k, v)
    })
    autoDone && this.onDone(false)
  }

  onDebouncedChange (k, v) {
    const { flags, rememberCard } = this.props
    const { isValid, doSaveApi, formatForApi } = FIELDS(flags).find(f => f.id === k)
    const doReallySaveApi = (doSaveApi && rememberCard)

    if (k === 'expires' && v.length === 4) {
      console.warn('[Card] [onDebouncedChange]->k===expires')
      const transformedValue = v.slice(0, 2) + '/' + v.slice(2)
      if (isValid(transformedValue, this.state.data)) {
        v = transformedValue
      }
    }

    const isReallyValid = isValid(v, this.state.data)
    console.warn('[Card] [onDebouncedChange]', { k, v, isReallyValid, doSaveApi, rememberCard, doReallySaveApi })
    if (formatForApi) {
      v = formatForApi(v, this.state.data)
    }
    doReallySaveApi && isReallyValid && window.sticky.session.updateCard({ [k]: v })
  }

  setIsLoading (isLoading) {
    this.setState({ isLoading })
  }

  async onDone (overrideRememberCard) {
    let { rememberCard } = this.props
    if (typeof overrideRememberCard === 'boolean') {
      rememberCard = overrideRememberCard
    }
    await asyncSetState(this.setState, { isLoading: true })
    const { data } = this.state
    const { onDone } = this.props
    rememberCard && window.sticky.session.updateCard(data)
    onDone && onDone(data)
  }

  goPrevious () {
    const { stageNumber } = this.state
    if (stageNumber === 0) {
      return
    }
    this.setState({
      stageNumber: stageNumber - 1
    })
  }
  goNext () {
    const { stageNumber } = this.state
    this.setState({
      stageNumber: stageNumber + 1
    })
  }

  render () {
    const { color, backgroundColor, flags = [], caresAboutCardName, doAvs, caresAboutCardCvv, hideCardUi, showCardImage, onDone, canVault, onLoad } = this.props
    const { data, stageNumber, isLoading, allCanGoNext, autoCard } = this.state
    if (!data) {
      return null
    }

    const cardType = getCardType(flags, data.number)
    const isFinalStage = stageNumber === STAGES.length - 1
    // const isFirstStage = stageNumber === 0
    const canGoBackAStage = stageNumber > 0
    const { Component: StageComponent, canGoNext } = STAGES[stageNumber]
    const doGoPrevious = () => {
      this.goPrevious()
    }
    const doGoNext = () => {
      if (!canGoNext(flags, data, caresAboutCardName, caresAboutCardCvv, doAvs)) {
        return
      }
      !isFinalStage && this.goNext()
      isFinalStage && this.onDone()
    }

    const buttonStyleProps = {
      color,
      backgroundColor
    }
    const stageProps = {
      FIELDS: FIELDS(flags),
      cardType,
      data,
      caresAboutCardName,
      doAvs,
      caresAboutCardCvv,
      hideCardUi,
      setIsLoading: this.setIsLoading,
      dbfProps: {
        onChange: this.onChange,
        onDebouncedChange: this.onDebouncedChange,
        onSubmit: doGoNext
      },
      color: backgroundColor // because it's originally about buttons; fuck my life
    }
    const fieldName = FIELDS(flags).find(f => f.id === 'name')
    const fieldNumber = FIELDS(flags).find(f => f.id === 'number')

    const GoBackButton = (
      <Button className='dont-munge-me' isSecondary onClick={doGoPrevious}>
        ←
      </Button>
    )

    return (
      <StyledCard className='component--card'>
        {
          (showCardImage || allCanGoNext) ? (
            <div className='card' style={{ backgroundImage: `url('${cardType.image}')` }}>
              {caresAboutCardName && <span className='part name'>{fieldName.format(data.name, data)}</span>}
              <span className='part expires'>{data.expires}</span>
              <span className='part number'>{fieldNumber.format(data.number, data)}</span>
            </div>
          ) : <div className='no-card-image'></div>
        }
        {cardType.notSupported && (
          <Banner mood='bad'>
            <p>{cardType.notSupported}</p>
          </Banner>
        )}
        {!allCanGoNext && <StageComponent {...stageProps} />}
        {!hideCardUi && <div className='buttons'>
          {allCanGoNext && (
            <>
              {!isLoading && <Button backgroundColor='transparent' color={backgroundColor} className='dont-munge-me' isSecondary onClick={() => this.setState({ allCanGoNext: false })}>
                <T>EDIT</T>
              </Button>}
              <Button
                className={classnames('dont-munge-me', { 'openbox--pulsing-1': !isLoading && !autoCard, 'openbox--blinking': autoCard })}
                onClick={() => {
                  !autoCard && this.onDone()
                  autoCard && this.stopAutoCard()
                }}
                disabled={isLoading || !onDone}
                {...buttonStyleProps}
                inlineIconString={autoCard && window.sticky.internals.icons.get('cross')}
              >
                {isLoading && <T>PLEASE_WAIT</T>}
                {!isLoading && autoCard && 'Stop'}
                {!isLoading && !autoCard && window.sticky._('PAY_NOW')}
              </Button>
            </>
          )}
          {!allCanGoNext && !isFinalStage && (
            <>
              {canGoBackAStage && GoBackButton}
              <Button className='dont-munge-me' onClick={doGoNext} disabled={!canGoNext(flags, data, caresAboutCardName, caresAboutCardCvv, doAvs)} {...buttonStyleProps}>
                Next →
              </Button>
            </>
          )}
          {!allCanGoNext && isFinalStage && (
            <>
              {canGoBackAStage && GoBackButton}
              <Button className={classnames('dont-munge-me', { 'openbox--pulsing-2': !isLoading  })} onClick={doGoNext} disabled={!canGoNext(flags, data, caresAboutCardName, caresAboutCardCvv, doAvs) || isLoading || !onDone} {...buttonStyleProps}>
                {!isLoading ? window.sticky._('PAY_NOW') : <T>PLEASE_WAIT</T>}
              </Button>
            </>
          )}
        </div>}
        {canVault && !allCanGoNext && isFinalStage && <div className='buttons'>
          <Button
            inlineIconString={window.sticky.internals.icons.get('arrowDown')}
            disabled={!canGoNext(flags, data, caresAboutCardName, caresAboutCardCvv, doAvs) || isLoading || !onDone}
            backgroundColor='transparent'
            color={backgroundColor}
            onClick={() => {
              ;(async () => {
                const r = await window.sticky.applications.blocks.renderInlineEventsButton(
                  [
                    {
                      'id': '0e1f0565-5e05-471c-b855-bbe44c20527d',
                      'config': {
                        'label': 'Reference',
                        'type': 'Text',
                        'value': window.sticky.Stickypay.getCrudValue('userPaymentId') || '',
                        'required': true
                      }
                    },
                    {
                      'id': '0e1f0565-5e05-471c-b855-bbe44c20527d',
                      'config': {
                        'label': 'Vault password',
                        'type': 'Password',
                        'value': '',
                        'required': true
                      }
                    }
                  ]
                    .filter(_ => _),
                  'Save',
                  {
                    foregroundColor: backgroundColor,
                    backgroundColor: color,
                    buttonIcon: 'arrowDown'
                  }
                )
                if (!r) {
                  return
                }
                try {
                  this.setIsLoading(true)
                  const hint = `Ending ${data.number.slice(-4)}`
                  const response = await window.sticky.users.vault.create(r['Vault password'], { name: r['Reference'], hint, data: JSON.stringify(data), type: 'CARD' })
                  window.sticky.flow.showMessage(response, { primaryColor: color, backgroundColor: backgroundColor })
                } catch ({ message }) {
                  await window.sticky.flow.showError(message, true)
                } finally {
                  this.setIsLoading(false)
                }
              })()
            }}
          >
            Save
          </Button>
          <Button
            inlineIconString={window.sticky.internals.icons.get('search')}
            backgroundColor='transparent'
            color={backgroundColor}
            onClick={() => {
              ;(async () => {
                const r1 = await window.sticky.applications.blocks.renderInlineEventsButton(
                  [
                    {
                      'id': '0e1f0565-5e05-471c-b855-bbe44c20527d',
                      'config': {
                        'label': 'Reference',
                        'type': 'Text',
                        'value': window.sticky.Stickypay.getCrudValue('userPaymentId') || '',
                        'required': true
                      }
                    },
                    {
                      'id': '0e1f0565-5e05-471c-b855-bbe44c20527d',
                      'config': {
                        'label': 'Vault password',
                        'type': 'Password',
                        'value': '',
                        'required': true
                      }
                    }
                  ]
                    .filter(_ => _),
                  'Check',
                  {
                    foregroundColor: backgroundColor,
                    backgroundColor: color,
                    buttonIcon: 'check'
                  }
                )
                if (!r1) {
                  return
                }
                let vaultItem
                try {
                  vaultItem = await window.sticky.users.vault.get(r1['Reference'])
                } catch ({ message }) {
                  window.sticky.applications.blocks.showError(message, true)
                  return
                }
                const r2 = await window.sticky.applications.blocks.getYesNo({ message: `Please check this card is right:\n\n${vaultItem.hint}`, buttonText: window.sticky._('PAY_NOW') })
                if (!r2) {
                  return
                }
                await onLoad({ vaultPassword: r1['Vault password'], vaultName: r1['Reference'] })
              })()
            }}
          >
            Load
          </Button>
        </div>}
      </StyledCard>
    )
  }
}
Card.propTypes = {
  onDone: PropTypes.func
}
