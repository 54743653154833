/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { icons, Form, PriceInput, Price, Modal, Button, Banner } from '@openbox-app-shared'

import _ from '../../../_'

const StyledModal = styled.div`
  .buttons {
    text-align: center;
    > * {
      margin-top: 1rem;
      display: inline-block;
    }
  }
`

function ThisModal ({ payment, refundAmount, onUpdate, onDone, onClose }) {
  const [isWorking, setIsWorking] = useState(false)
  return (
    <Modal onClose={onClose}>
      <StyledModal>
        <Form
          onChange={(v, k) => onUpdate(k)}
          onSubmit={onDone}
        >
          <PriceInput
            name='amount'
            value={refundAmount}
            autoFocus
          />
        </Form>
        <div className='buttons'>
          <Button
            onClick={() => {
              setIsWorking(true)
              onDone()
            }}
            icon={icons.inverted.check}
            disabled={isWorking}
          >
            {isWorking ? 'Refunding' : 'Refund'} <Price price={refundAmount} currency={payment.currency} />
          </Button>
        </div>
      </StyledModal>
    </Modal>
  )
}
ThisModal.propTypes = {
  payment: PropTypes.object.isRequired,
  refundAmount: PropTypes.number.isRequired,
  onUpdate: PropTypes.func,
  onDone: PropTypes.func,
  onClose: PropTypes.func
}

export default {
  trigger: ({ setState, why, payment }) => {
    setState({ PAYMENT_REFUND: { why, payment } })
  },
  render: function ThisAction ({ user, state, setState, dispatch }) {
    const { PAYMENT_REFUND } = state
    if (!PAYMENT_REFUND) {
      return null
    }
    const { why, payment } = PAYMENT_REFUND
    const [refundAmount, setRefundAmount] = useState(payment.howMuchCanRefund)
    const isReallyDone = async () => {
      const eventPayload = {
        type: 'SESSION_CART_REFUND',
        paymentId: payment.id,
        refundAmount,
        refundCurrency: payment.currency
      }
      const callback = async (e) => {
        if (e) {
          dispatch('SHOW_MESSAGE', { message: <><p><strong>Refund failed!</strong></p><p>{e.message}</p></>, canBeBadded: '' })
          setState({ PAYMENT_REFUND: undefined })
          return
        }
        payment.refund(refundAmount, user.federatedUser && user.federatedUser.id)
        dispatch('PAYMENT_REFUND_GOOD', { why })
        setState({ PAYMENT_REFUND: undefined })
      }
      await window.sticky.session.createEvent(
        eventPayload,
        'doesnt-matter',
        payment.application && payment.application.id,
        payment.thing && payment.thing.id,
        callback
      )
    }
    return (
      <>
        <ThisModal
          payment={payment}
          refundAmount={refundAmount}
          onUpdate={v => setRefundAmount(v)}
          onDone={isReallyDone}
          onClose={() => {
            setState({
              PAYMENT_REFUND: undefined
            })
          }}
        />
      </>
    )
  }
}
