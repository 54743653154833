import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

const StyledLoading = styled.div`
  @keyframes gmc-loading {
    100% { transform: rotate(360deg); }
  }
  display: block;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #c4cad0;
  svg {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 auto 0 auto;
    animation: gmc-loading 4s linear infinite;
    color: inherit;
    // background-color: blue;
  }
  p {
    margin: 0 auto 0 auto;
    text-align: center;
    color: inherit;
  }
  svg + p {
    margin-top: 1rem;
  }
`

const Loading = ({ style, loadingWhat }) => {
  return (
    <StyledLoading style={style} className='component--loading'>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z" fill="currentColor"><animateTransform attributeName="transform" type="rotate" dur="1s" values="0 12 12;360 12 12" repeatCount="indefinite"/></path></svg>
      {loadingWhat && <p className='well-known--loading-what'>{loadingWhat}</p>}
    </StyledLoading>
  )
}
Loading.propTypes = {
  style: PropTypes.object,
  loadingWhat: PropTypes.string
}

export default Loading
