export default function getHomeUrl (federatedUserId, federatedUserRoles, rolesToSkip = []) {
  const finalRoles = federatedUserRoles.filter(_ => !rolesToSkip.includes(_))
  if (finalRoles.includes('lock--sticky-pay')) {
    return '/sticky-pay'
  }
  if (finalRoles.includes('lock--to-do')) {
    return '/to-do'
  }
  if (finalRoles.includes('applications')) {
    return '/me/flows'
  }
  if (finalRoles.includes('things')) {
    return '/me/stickies'
  }
  if (finalRoles.includes('payments')) {
    return '/analytics/payments'
  }
  return `/me/team/${federatedUserId}`
}
