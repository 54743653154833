import React, { Component } from 'react'
import { createRoot } from 'react-dom/client'
import PropTypes from 'prop-types'
import {
  BrowserRouter as Router,
  Switch,
  Redirect as BrowserRedirect,
  Route as BrowserRoute
} from 'react-router-dom'
import styled from 'styled-components'

const HUBSPOT_ID = '9210678'

import { hotLoader, hotLoaderSdk, Link, Bar } from '@openbox-app-shared'

import { version } from '../package.json'
import RouteSetUp from './routes/setUp'
import RouteCreateUser from './routes/createUser/createUser'
import RouteLogIn from './routes/logIn/logIn'
import RouteResetPassword from './routes/resetPassword/resetPassword'

import RouteUsers from './routes/users'
import RouteUser from './routes/user'
import RouteAccount from './routes/account'

import RouteThing from './routes/thing'
import RouteReports from './routes/reports'
import RouteDeveloper from './routes/developer'
import RouteShortLinks from './routes/shortLinks'
import RouteEvent from './routes/event/event'
import RouteApplication from './routes/application'
import RouteProduct from './routes/product'
import RouteStickypayPayment from './routes/stickypay/payment'
import RouteStickypayPaymentInvoice from './routes/stickypay/paymentInvoice'

import RouteFederatedUser from './routes/federatedUser'

import RoutePartners from './routes/partners'

import RouteGodMode from './routes/godMode'
import RoutePartner from './routes/partner/partner'

import RouteToDo from './routes/views/stickyretail'
import RouteToDoLinear from './routes/views/stickyretailSimple'
import RouteThingsOverview from './routes/views/thingsOverview/thingsOverview'
import RouteOpsManagerV1 from './routes/views/opsManager/v1'
import RouteOpsManagerV2 from './routes/views/opsManager/v2'

import RouteYeetYeet from './routes/views/yeetYeet'
import RouteYeetStickyPay from './routes/views/yeetStickyPay'

import { getLogInUrlWrapper } from './getLogInUrl'

import './index.css'
import { log } from './log'
import dashboardIcons from './icons'
import { ReduxHandler } from './components/ReduxHandler'
import { dispatch } from './redux'
import classnames from 'classnames'

function getAutoUi () {
  const autoUi = (() => {
    const queryObject = new URLSearchParams(window.location.search)
    const autoUi = queryObject.get('autoUi')
    return typeof autoUi === 'string' ? autoUi.split(',') : []
  })()
  return autoUi
}

const StyledApp = styled.div`
  main {
    margin: 0 auto 0 auto;
    max-width: 1192px;
  }
  main[data-auto-ui-nomainframeaside] {
    .component--main-frame.expanded {
      .expand-container {
        display: none;
      }
    }
    .component--user, .component--user + hr {
      display: none;
    }
  }
  .version {
    position: fixed;
    bottom: 0.05rem;
    right: 0.1rem;
    padding: 0.05rem;
    z-index: 1;
    font-size: 75%;
    opacity: 0.5;
  }
  .component--navbar {
    &.will-render {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    background-color: white;
  }
  .major-error {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 512px;
    max-width: calc(100% - 32px);
    color: white;
    text-align: center;
    white-space: break-spaces;
  }
`

const ROUTES_THAT_DONT_NEED_AUTHENTICATION = [
  '/sign-up',
  '/log-in',
  '/reset-password',
  '/partners/',
  '/sign/'
]

const ROUTES_THAT_DONT_SHOW_VERSION = [
  '/sticky-pay',
  '/sticky-pay-sticky-pay',
  '/device'
]

const routeDoesntNeedAuthentication = () => {
  const p = window.location.pathname
  return typeof window === 'undefined' ? true : (
    p === '/' || ROUTES_THAT_DONT_NEED_AUTHENTICATION.some(r => p.startsWith(r))
  )
}

const CustomRoutePropTypes = {
  WhichComponent: PropTypes.any,
  path: PropTypes.string
}
const CustomRouteGeneric = ({ WhichComponent, path, ...rest }) => {
  const autoUi = getAutoUi()
  const datasetProps = {}
  autoUi.forEach(_ => {
    datasetProps[`data-auto-ui-${_.toLowerCase()}`] = ''
  })
  return (
    <main role="presentation" {...datasetProps}>
      <BrowserRoute
        path={path}
        render={props => {
          const autoUi = getAutoUi()
          return <WhichComponent autoUi={autoUi} {...rest} {...props} />
        }}
      />
    </main>
  )
}

const CustomRouteFat = ({ WhichComponent, path, ...rest }) => {
  return (
    <BrowserRoute
      path={path}
      render={props => {
        const autoUi = getAutoUi()
        return <WhichComponent autoUi={autoUi} {...rest} {...props} />
      }}
    />
  )
}
CustomRouteGeneric.propTypes = CustomRoutePropTypes
CustomRouteFat.propTypes = CustomRoutePropTypes

function NavBar({ user }) {
  const willRender = user && (
    user.can('get-users') ||
    user.can('create-partner') ||
    user.can('get-partners') ||
    user.can('get-partners-all') ||
    user.can('god-mode')
  )
  return <Bar className={classnames('print-hide', 'component--navbar', { 'will-render': willRender })}>
    {user && user.can('get-users') && <li>
      <Link to='/me/flows'>
        <dashboardIcons.home />
        <span className='name'>Home</span>
      </Link>
    </li>}
    {user && user.can('get-users') && (<li>
      <Link to='/internal/users'>
        <dashboardIcons.teamMember />
        <span className='name'>Dashboards</span>
      </Link>
    </li>)}
    {user && (user.can('create-partner') || user.can('get-partners') || user.can('get-partners-all')) && (<li>
      <Link to='/internal/partners'>
        <dashboardIcons.teamMember />
        <span className='name'>Partners</span>
      </Link>
    </li>)}
    {user && (user.can('god-mode')) && (<li>
      <Link to='/internal/god-mode'>
        <dashboardIcons.search />
        <span className='name'>Mr Tweedy</span>
      </Link>
    </li>)}
  </Bar>
}
NavBar.propTypes = {
  user: PropTypes.object
}

class App extends Component {
  constructor() {
    super()
    log('[App->js] [constructor]')
    this.state = {
      user: undefined
    }
    this.onUpdateUser = this.onUpdateUser.bind(this)
    this.onSaveUser = this.onSaveUser.bind(this)
  }

  onUpdateUser(payload) {
    log('[App->js] [onUpdateUser]', { payload })
    this.state.user.patch(payload)
    this.forceUpdate()
  }

  async onSaveUser(props, callCacheBusters) {
    log('[App->js] [onSaveUser]', { props })
    try {
      const newUser = await window.sticky.users.save(undefined, props, callCacheBusters)
      dispatch('REFRESH_REDUX')
      return newUser
    } catch (e) {
      dispatch('SHOW_MESSAGE', { message: <><p><strong>Hmm; that didn't work.</strong></p><p>{e.message}</p></>, canBeBadded: '' })
      throw new Error()
    }
  }

  async componentDidMount() {
    log('[App->js] [componentDidMount]')

    window.hsConversationsSettings = {
      loadImmediately: false,
      enableWidgetCookieBanner: true,
    }

    await hotLoaderSdk(version)
    window.sticky.internals.setClientVersion(version)
    window.sticky.setLogInCallback(
      () => {
        if (routeDoesntNeedAuthentication()) {
          return
        }
        if (window.location.pathname !== '/log-in') {
          const { logInRedirectTo } = getLogInUrlWrapper()
          window.location = logInRedirectTo
        }
      }
    )

    let user
    try {
      user = await window.sticky.users.getMe(true)
      window.stickySessionLanguage = [user.language]
      window._ = window.sticky._
      log('[App->js] [componentDidMount] user succeeded', { user })
      user.partner && window.sticky._.extend(user.partner.underscoreExtension.get())

      try {
        user.can('get-support') && await hotLoader(`https://js.hs-scripts.com/${HUBSPOT_ID}.js`, 'js')
      } catch (e) {}

      this.freeStyle = user.partner ? window.sticky.addStyle(undefined, user.partner.css) : undefined
      this.setState({ user })
    } catch ({ message }) {
      if (!routeDoesntNeedAuthentication()) {
        this.setState({
          majorError: `This isn\'t working at all.\n\nDo you have an internet connection?\n\n(${message})`
        })
      } else {
        this.forceUpdate()
      }
      // log('[App->js] [componentDidMount] user failed; falling back', { error })
      // window.sticky.setPublicKey('public-c0b88579-1463-4459-8534-4484ae6028d0')
      // user = await window.sticky.users.getMe(true)
      // this.setState({ user })
    } finally {
      (bl => {
        bl.style.opacity = 0
        this.hideBootLoadingTimeout = setTimeout(() => { bl && bl.remove() }, 1000)
      })(document.getElementById('boot--loading'))
    }

    const versionsAsSet = new Set([version, window.sticky.version])
    if (versionsAsSet.size > 1) {
      window.sticky.applications.blocks.showMessage(`An update is available:\n\n<strong>${window.sticky.version}</strong>\n\nPlease wait...`, undefined, false)
      setTimeout(
        () => {
          window.location.reload()
        },
        5 * 1000
      )
    }
    if (['iPhone', 'iPad'].some(_ => navigator.userAgent.includes(_)) && navigator.userAgent.includes(' Version/14.')) {
      window.sticky.applications.blocks.showError('⚠️ You are using iOS 14.\n\nPlease update your device!', true)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.hideBootLoadingTimeout)
    this.freeStyle && this.freeStyle()
  }

  render() {
    const { user, majorError } = this.state
    const isUserInState = typeof user === 'object'
    const routeDoesntActuallyNeedAuthentication = routeDoesntNeedAuthentication()
    const canRender = routeDoesntActuallyNeedAuthentication ? (window.sticky ? true : false) : isUserInState
    log('[App->js] [render]', { routeDoesntActuallyNeedAuthentication, isUserInState, canRender })
    if (majorError) {
      return (
        <StyledApp>
          <p className='major-error'>{majorError}</p>
        </StyledApp>
      )
    }
    if (!canRender) {
      return null
    }
    const userPreferences = user ? user.preferences.get() : {}
    const authenticatedProps = {
      user,
      userPreferences,
      onUpdateUser: this.onUpdateUser,
      onSaveUser: this.onSaveUser
    }
    const notAuthenticatedProps = {
    }
    return (
      <>
        <Router>
          <StyledApp>
            {!ROUTES_THAT_DONT_SHOW_VERSION.includes(window.location.pathname) && 
              <strong
                className='print-hide version'
                onClick={async () => {
                  dispatch('LOADING')
                  const newVersionAsString = await window.sticky.internals.getRemoteVersion()
                  const newVersionAsInt = parseInt(newVersionAsString.split('.').join(''))
                  const currentVersionAsString = version
                  const currentVersionAsInt = parseInt(version.split('.').join(''))
                  dispatch('STOP_LOADING')
                  if (newVersionAsInt > currentVersionAsInt) {
                    window.sticky.applications.blocks.showMessage(`An update is available:\n\n<strong>${newVersionAsString}</strong>\n\nPlease wait...`, undefined, false)
                    setTimeout(
                      () => {
                        window.location.reload()
                      },
                      5 * 1000
                    )
                  } else {
                    window.sticky.applications.blocks.showMessage(`You are up-to-date:\n\n<strong>${currentVersionAsString}</strong>`)
                  }
                }}
              >
                D{version}-S{window.sticky ? window.sticky.version : '?'}
              </strong>
            }
            <NavBar user={user} />
            <Switch>
              <CustomRouteGeneric WhichComponent={RouteSetUp} path="/set-up" {...authenticatedProps} />

              <CustomRouteGeneric WhichComponent={RouteShortLinks} path="/me/links" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteStickypayPaymentInvoice} path="/me/payments/:paymentId/invoice" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteStickypayPayment} path="/me/payments/:paymentId/:view" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteStickypayPayment} path="/me/payments/:paymentId" {...authenticatedProps} />

              <CustomRouteGeneric WhichComponent={RouteEvent} path="/me/activity/:eventId" {...authenticatedProps} />

              <CustomRouteGeneric WhichComponent={RouteThing} path="/me/stickies/:thingId/:view" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteThing} path="/me/stickies/:thingId" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteApplication} path="/me/flows/:applicationId/:view/:viewSubroot" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteApplication} path="/me/flows/:applicationId/:view" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteApplication} path="/me/flows/:applicationId" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteProduct} path="/me/products/:productId/:view" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteProduct} path="/me/products/:productId" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteFederatedUser} path="/me/team/:federatedUserId" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteUser} path="/me/:entity/:subEntity" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteUser} path="/me/:entity" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteAccount} path="/my/account/:entity/:subEntity" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteAccount} path="/my/account/:entity" {...authenticatedProps} />

              <CustomRouteGeneric WhichComponent={RouteCreateUser} strict exact path="/sign-up/:partnerCode" {...notAuthenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteCreateUser} strict exact path="/sign-up" {...notAuthenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteLogIn} strict exact path="/log-in/:partnerCode" {...notAuthenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteLogIn} strict exact path="/log-in" {...notAuthenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteResetPassword} strict exact path="/reset-password/:finiteToken" {...notAuthenticatedProps} />
              <CustomRouteGeneric WhichComponent={RoutePartner} strict exact path="/partners/:partnerId" {...notAuthenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteGodMode} strict exact path="/internal/god-mode" {...notAuthenticatedProps} />

              <CustomRouteGeneric WhichComponent={RouteReports} path="/analytics/:reportId/:subEntity/:subEntityId" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteReports} path="/analytics/:reportId" {...authenticatedProps} />

              <CustomRouteGeneric WhichComponent={RouteDeveloper} path="/developer/:view/:entityId/:viewSubroot" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteDeveloper} path="/developer/:view/:entityId" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteDeveloper} path="/developer/:view" {...authenticatedProps} />

              <CustomRouteFat WhichComponent={RouteUsers} strict exact path="/internal/users" {...authenticatedProps} />
              <CustomRouteFat WhichComponent={RoutePartners} strict exact path="/internal/partners" {...authenticatedProps} />

              <CustomRouteGeneric WhichComponent={RouteToDo} strict exact path="/to-do" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteToDoLinear} strict exact path="/to-do-linear/:federatedUserId" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteToDoLinear} strict exact path="/to-do-linear" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteThingsOverview} strict exact path="/stickies-overview" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteOpsManagerV1} strict exact path="/ops-manager" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteOpsManagerV2} strict exact path="/ops-manager-2" {...authenticatedProps} />

              <CustomRouteGeneric WhichComponent={RouteYeetYeet} strict exact path="/sticky-pay" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteYeetStickyPay} strict exact path="/sticky-pay-sticky-pay" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteOpsManagerV2} strict exact path="/device" {...authenticatedProps} />

              <CustomRouteGeneric WhichComponent={RouteUser} strict path="/users/:userId" {...authenticatedProps} />

              {/* Legacy */}
              <CustomRouteGeneric WhichComponent={RouteToDo} strict exact path="/views/stickyretail" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteToDoLinear} strict exact path="/views/stickyretail-simple/:federatedUserId" {...authenticatedProps} />
              <CustomRouteGeneric WhichComponent={RouteToDoLinear} strict exact path="/views/stickyretail-simple" {...authenticatedProps} />
              {/* End legacy */}

              <BrowserRoute path="*">
                <BrowserRedirect to='/log-in' />
              </BrowserRoute>
            </Switch>
            <ReduxHandler
              onReady={entities => {
                dispatch('REDUX_HANDLER_READY', entities)
                if (!user) {
                  return
                }
                if (user.partnerCan('onboard') && !user.onboarded) {
                  dispatch('ONBOARDING')
                  return
                }
              }}
            />
          </StyledApp>
        </Router>
      </>
    )
  }
}

const root = createRoot(document.getElementById('root'))
root.render(<App />)
