import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Price, Table } from '@openbox-app-shared'
import H2 from '../H2'

const StyledReport = styled.div`
  .component--table {
    width: 100%;
  }
  .component--h2 {
    margin-top: 0rem;
    margin-bottom: 0.5rem;
  }
`

export default function Report ({ user, data }) {
  const { rowsUsers } = data
  return (
    <StyledReport>
      <H2>Dashboards</H2>
      <Table
        columns={{
          user_name: '',
          sum_total: 'Total',
          sum_count: 'How many?',
          days_since_last_payment: 'Days since last payment'
        }}
        rows={rowsUsers.map(_ => ({ ..._, sum_total: <Price price={parseInt(_.sum_total, 10)} currency={_.user_currency} /> }))}
      />
    </StyledReport>
  )
}
Report.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object
}
