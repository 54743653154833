const FILE_TYPES = new Map([
  [
    'js',
    src => {
      const e = document.createElement('script')
      e.type = 'text/javascript'
      e.src = src
      return e
    }
  ],
  [
    'css',
    src => {
      const e = document.createElement('link')
      e.rel = 'stylesheet'
      e.type = 'text/css'
      e.href = src
      return e
    }
  ]
])

function loadScript (src, fileType) {
  if (typeof window === 'undefined') return
  const makeFileTypeElement = FILE_TYPES.get(fileType)
  return new Promise(resolve => {
    let hasLoaded = false
    const newElement = makeFileTypeElement(src)
    newElement.onload = newElement.onreadystatechange = function () {
      if (!hasLoaded && (!this.readyState || this.readyState === 'complete')) {
        hasLoaded = true
        resolve()
      }
    }
    document.head.appendChild(newElement)
  })
}

const getEndpoint = version => {
  const environments = {
    'https:': `https://cdn.sticky.to/sdk/sticky-sdk-production-${version}.js`,
    'http:': `http://localhost:2056/sdk/sticky-sdk-local-${version}.js`
  }
  const foundEnvironment = environments[window.location.protocol]
  const isInstantDebit = window.location.hostname.endsWith('instantdebit.co.uk')
  const isPreprod = window.location.hostname.endsWith('sticky.bargains')
  const isNgrok = window.location.hostname.endsWith('.eu.ngrok.io')
  console.warn('[getEndpoint]', { windowLocationProtocol: window.location.protocol, isNgrok })
  if (isInstantDebit) {
    return `https://cdn.instantdebit.co.uk/sdk/sticky-sdk-production-${version}.js`
  }
  if (isPreprod) {
    return `https://cdn.sticky.bargains/sdk/sticky-sdk-production-${version}.js`
  }
  if (isNgrok) {
    return `https://sticky-openbox-cdn.eu.ngrok.io/sdk/sticky-sdk-local-${version}.js`
  }
  return (foundEnvironment || environments['https:'])
}

export async function hotLoader (endpoint, fileType = 'js') {
  await loadScript(endpoint, fileType)
}

export async function hotLoaderSdk (version = 'v1') {
  await loadScript(getEndpoint(version), 'js')
}
