import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Button, TimeDifference, InlineHtml } from '@openbox-app-shared'
import Box from '../../../../components/Box'
import CoolButton from '../../../../components/CoolButton'
import UserCircle from '../../../../components/UserCircle'
import dashboardIcons from '../../../../icons'

const StyledComponent = styled.div`
  ${props => css`
  > .component--box {
    position: relative;
    height: 100%;
    padding-bottom: 0;
    &.hidden {
      opacity: 0.35;
    }
  }
  .what {
    font-size: 200%;
  }
  .what, hr {
    color: ${props.fgColor};
  }
  .controls-1 {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 4rem;
    .component--button {
      width: 4rem;
      height: 4rem;
      padding: 0;
      > svg {
        width: 2rem;
        height: 2rem;
        margin-bottom: 0;
      }
    }
  }
  .controls-2 {
    margin-top: 1rem;
    float: right;
    > * {
      display: inline-block;
      vertical-align: top;
      margin-right: 0.5rem;
      margin-bottom: 1rem;
    }
    > *:last-child {
      margin-right: 0;
    }
    > strong, > span {
      height: 2.5rem;
      line-height: 2.5rem;
      color: ${props.fgColor};
    }
    > strong {
      font-size: 120%;
    }
    > span {
      font-size: 90%;
    }
  }
  .details {
    position: relative;
    pointer-events: none;
    margin-right: 4.5rem;
    > hr {
      border-color: ${props.fgColor};
      opacity: 0.1;
    }
    > img {
      display: block;
      width: 100%;
      border-radius: 6px;
      box-shadow: 0 3px 6px 0 rgba(60, 68, 86, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    }
    > img + p {
      margin-top: 1rem;
    }
    > .with-icons {
      min-height: 4rem;
      margin-bottom: 1rem;
      > * {
        line-height: 1.5rem;
        font-size: 110%;
      }
      > * + * {
        margin-top: 0.75rem;
      }
      .with-icon > * {
        color: ${props.fgColor};
      }
      .with-icon {
        > svg {
          float: left;
          margin-right: 0.5rem;
        }
        > span, > strong {
          line-height: 1.5rem;
        }
        img, svg {
          display: block;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
      .with-icon.thing {
        border: 2px solid ${props.fgColor};
        padding: 0.5rem;
        border-radius: 6px;
        width: fit-content;
      }
    }
  }
  .cb-done {
    font-size: 0.75rem;
  }
  `}
`

function hexToRgbArray (hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ] : [0, 0, 0]
}

function invertRgbArray (array) {
  return array.map(_ => 255 - _)
}

function rgbArrayToCssString (array) {
  return `rgb(${array.join(',')})`
}

export default function Card ({ userPreferences, rdic, entity, currentFederatedUser, isDone, onAction }) {
  const pCurrentFederatedUser = rdic.federatedUsers.find(fu => fu.id === entity.federatedUserId)
  const thing = rdic.things.find(t => t.id === entity.thingId)
  const application = rdic.applications.find(t => t.id === entity.applicationId)
  const bgColor = entity.customData.readFrom('colour')
  const fgColor = entity.customData.readFrom('foregroundColor') || rgbArrayToCssString(invertRgbArray(hexToRgbArray(bgColor)))

  let takeASelfie = entity.autoExpandedSession ? entity.autoExpandedSession.crossUserSector.readFrom('Take a selfie') : undefined
  takeASelfie = window.sticky.getDeserializedValue(takeASelfie, undefined, false)

  const classNames = [
    !isDone && window.sticky.applications.blocks.specialEffectClassNames.get(entity.customData.readFrom('specialEffect'))
  ]
    .filter(e => e)

  return (
    <StyledComponent fgColor={fgColor} className={classNames}>
      <Box className={isDone ? 'hidden clearfix' : 'clearfix'} style={{ backgroundColor: bgColor }}>
        <div className='controls-1'>
          {!isDone && <Button
            className='cb-done'
            InlineIcon={dashboardIcons.check}
            backgroundColor={fgColor}
            color={bgColor}
            bigIcon
            onClick={() => onAction('done')}
          >
            Complete
          </Button>}
        </div>
        <div className='details'>
          <div className='with-icons'>
            <h1 className='clearfix with-icon time'>
              <InlineHtml html={window.sticky.internals.icons.get('time')} />
              <strong><TimeDifference time={entity.time} timezone={rdic.user.timezone} fallbackToJustNow /></strong>
            </h1>
            {thing && <h1 className='clearfix with-icon thing'><dashboardIcons.thing /><span>{thing.name}</span></h1>}
            {application && <h1 className='clearfix with-icon application'><dashboardIcons.application /><span>{application.name}</span></h1>}
          </div>
          <hr />
          {entity.customData.readFrom('image') && <img src={entity.customData.readFrom('image')} alt={entity.customData.readFrom('what')} />}
          {takeASelfie && <img src={takeASelfie} />}
          <p className='what'>{entity.customData.readFrom('what')}</p>
        </div>
        <div className='controls-2 clearfix'>
          {[true, undefined].includes(userPreferences.toDoAccessCus) && <CoolButton
            color={fgColor}
            InlineIcon={dashboardIcons.teamMember}
            title='About this consumer'
            onClick={() => onAction('session')}
          />}
          {entity.moreEventIds.toArray().map(mi => {
            return (
              <CoolButton
                key={mi}
                color={fgColor}
                InlineIcon={dashboardIcons.expand}
                onClick={() => onAction('event', mi)}
              />
            )
          })}
          {[true, undefined].includes(userPreferences.toDoAssignPayments) && !entity.federatedUserId && <CoolButton
            color={fgColor}
            onClick={() => onAction('changeFederatedUser')}
          >
            Assign
          </CoolButton>}
          {pCurrentFederatedUser && <UserCircle fixedWidthHeight={2.5} name={pCurrentFederatedUser.name} color={pCurrentFederatedUser.color} photoUrl={pCurrentFederatedUser.photoUrl} onClick={() => onAction('removeFederatedUser')} />}
        </div>
      </Box>
    </StyledComponent>
  )
}
Card.propTypes = {
  userPreferences: PropTypes.object.isRequired,
  autoExpandedSession: PropTypes.object.isRequired,
  rdic: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
  currentFederatedUser: PropTypes.object,
  isDone: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired
}
