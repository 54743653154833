/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { icons, isUuid, ExpandCollapse, PatchableSetList, Modal, Form, DatePicker, UploadDynamic, Button, TimePicker, DayPicker, Switch, Input, NumberInput, DynamicInput, PriceInput, UrlInput, Dropdown, Label, ColourPicker, Banner } from '@openbox-app-shared'
import { log } from '../../../log'
import dashboardIcons from '../../../icons'

import H1 from '../../H1'
import CodeEditor2 from '../../CodeEditor2'
import KeyValuePairs from '../../KeyValuePairs'
import ListEditor from '../../ListEditor'
import ButtonList from '../../ButtonList'
import Box from '../../Box'
import ApplicationBlockChooser from '../../ApplicationBlockChooser'
import ThingChooser from '../../ThingChooser'
import ApplicationChooser from '../../ApplicationChooser'
import ChooseResource from '../../ChooseResource'
import FederatedUsersList from '../../FederatedUsersList'
import TagChooserUser from '../../TagChooserUser'
import UploadCompressedVideo from '../../UploadCompressedVideo'

function Actions ({ action, actions, stuff, doGetClientLocation, onChangeOthers, columns = 2 }) {
  return (
    <div className={`buttons grid-${columns}`}>
      {actions
        .map(a => {
          return (
            <Button
              key={a.id}
              turnedOn={action === a.id && '#26de81'}
              isSecondary
              bigIcon
              onClick={() => {
                const { label: newLabel, data: newData } = actions.find(ea => ea.id === a.id)
                const payload = {
                  action: `${a.id}~~||~~${newData(stuff)}~~||~~${doGetClientLocation ? 'true' : 'false'}`
                }
                if (newLabel) {
                  payload.label = newLabel(stuff)
                }
                onChangeOthers(payload)
              }}
              icon={a.icon}
              InlineIcon={a.uiIcon}
              inlineIconString={a.uiIconInline && a.uiIconInline()}
              backgroundColor={a.backgroundColor}
              color={a.foregroundColor}
            >
              {a.name}
            </Button>
          )
        })
      }
    </div>
  )
}

function EventScope({ stuff, label, value, onChange }) {
  return (
    <>
      {label && <Label>{label}</Label>}
      <div className='button-list grid grid-2'>
        <Button
          isSecondary
          turnedOn={value === 'user-session-type' && '#26de81'}
          onClick={() => onChange('user-session-type')}
        >
          All my flows
        </Button>
        <Button
          isSecondary
          turnedOn={value === 'application-session-type' && '#26de81'}
          onClick={() => onChange('application-session-type')}
        >
          Just this flow
        </Button>
      </div>
      {/* {value === 'application-session-type' && <Banner mood='good'><p>Only data from this flow will count.</p></Banner>}
      {value === 'user-session-type' && <Banner mood='good'><p>All data for {stuff.user.name} will count.</p></Banner>} */}
    </>
  )
}

function SectorScope({ stuff, label, value, onChange }) {
  return (
    <>
      <Label>{label}</Label>
      <div className='button-list grid grid-2'>
        <Button
          isSecondary
          turnedOn={value === 'local' && '#26de81'}
          onClick={() => onChange('local')}
        >
          Just this run
        </Button>
        <Button
          isSecondary
          turnedOn={value === 'application' && '#26de81'}
          onClick={() => onChange('application')}
        >
          This flow<br />Each consumer
        </Button>
        <Button
          isSecondary
          turnedOn={value === 'user' && '#26de81'}
          onClick={() => onChange('user')}
        >
          All my flows<br />Each consumer
        </Button>
        <Button
          isSecondary
          turnedOn={value === 'cross-user' && '#26de81'}
          onClick={() => onChange('cross-user')}
        >
          Every flow<br />Each consumer
        </Button>
      </div>
      {/* <div className='button-list grid'>
        <Button
          isSecondary
          turnedOn={value === 'user-all' && '#26de81'}
          onClick={() => onChange('user-all')}
        >
          This dashboard<br />Every consumer
        </Button>
      </div> */}
      {/* {value === 'application' && <Banner mood='good'><p>Every other flow will have a different value.</p></Banner>}
      {value === 'user' && <Banner mood='good'><p>Every flow for {stuff.user.name} will have the same value.</p></Banner>} */}
      {value === 'cross-user' && <Banner mood='bad'><p>Every flow in the world will have the same value.</p></Banner>}
    </>
  )
}

const ACTIONS = [
  {
    id: 'application',
    type: 'application',
    uiIcon: dashboardIcons.application,
    name: 'Go to flow',
    // label: () => 'Flow',
    data: (stuff) => stuff.applications.length > 0 ? stuff.applications[0].id : ''
  },
  {
    id: 'formSubmit',
    type: 'application--none-todo',
    uiIconInline: () => window.sticky.internals.icons.get('check'),
    name: 'Submit form',
    // flow ID, create a to do, to do text, to do colour
    data: (stuff) => ['', 'false', '', '#2f3542'].join('%%^^%%'),
    getClientLocation: true
  },
  {
    id: 'redirectApplicationPrevious',
    uiIconInline: () => window.sticky.internals.icons.get('arrowLeft'),
    name: 'Previous flow',
    data: (stuff) => [''].join('%%^^%%'),
    canShow: user => user.can('list-applications'),
    foregroundColor: '#DD2E44'
  },
  {
    id: 'redirectApplicationNext',
    uiIconInline: () => window.sticky.internals.icons.get('arrowRight'),
    name: 'Next flow',
    data: (stuff) => [''].join('%%^^%%'),
    canShow: user => user.can('list-applications'),
    foregroundColor: '#DD2E44'
  },
  {
    id: 'url',
    type: 'url',
    uiIcon: dashboardIcons.link,
    name: 'Link',
    // label: () => '→ Website',
    data: () => 'https://example.com'
  },
  {
    id: 'pay',
    uiIcon: dashboardIcons.payment,
    name: 'Pay',
    // label: () => 'Pay',
    data: (stuff) => ''
  },
  {
    id: 'thing',
    type: 'thing',
    uiIcon: dashboardIcons.thing,
    name: 'Go to sticky',
    // label: () => 'Sticky',
    data: (stuff) => stuff.things.length > 0 ? stuff.things[0].id : ''
  },
  {
    id: 'application--inline',
    uiIcon: dashboardIcons.expand,
    type: 'application--none-todo',
    name: 'Pop up flow',
    // label: (stuff) => stuff.applications.length > 0 ? stuff.applications[0].name : 'Pop up flow',
    data: (stuff) => [stuff.applications.length > 0 ? stuff.applications[0].id : '', 'false', '', '#2f3542'].join('%%^^%%')
  },
  {
    id: 'application--inline-destroy',
    uiIconInline: () => window.sticky.internals.icons.get('cross'),
    name: 'Close pop up',
    // label: (stuff) => 'Done',
    data: (stuff) => ''
  },
  {
    id: 'addProduct',
    type: 'product',
    uiIconInline: () => window.sticky.internals.icons.get('plus'),
    name: 'Add to bag',
    // label: () => 'Add to bag',
    data: (stuff) => stuff.products.length > 0 ? stuff.products[0].id : ''
  },
  {
    id: 'product',
    type: 'product',
    uiIcon: dashboardIcons.product,
    name: 'Go to product',
    // label: () => 'Product',
    data: (stuff) => stuff.products.length > 0 ? stuff.products[0].id : ''
  },
  {
    id: 'run_event_on_pay',
    uiIconInline: () => window.sticky.internals.icons.get('check'),
    getClientLocation: true,
    name: 'Success steps',
    data: (stuff) => [''].join('%%^^%%')
  },
  {
    id: 'email',
    type: 'email',
    uiIconInline: () => window.sticky.internals.icons.get('email'),
    name: 'Email',
    // label: (stuff) => 'Email us',
    data: (stuff) => stuff.user.email || 'hi@example.com'
  },
  {
    id: 'phone',
    type: 'phone',
    uiIconInline: () => window.sticky.internals.icons.get('phone'),
    name: 'Phone',
    // label: (stuff) => 'Call us',
    data: (stuff) => stuff.user.phone || '0113 314 1024'
  },
  {
    id: 'share',
    uiIconInline: () => window.sticky.internals.icons.get('share'),
    name: 'Share',
    data: (stuff) => [''].join('%%^^%%')
  },
  {
    id: 'emailReceipt',
    uiIconInline: () => window.sticky.internals.icons.get('email'),
    name: 'Email receipt',
    type: 'application--none',
    data: (stuff) => [''].join('%%^^%%')
  },
  {
    id: 'blockNextStep',
    type: 'blockNextStep',
    name: 'Wait to be pressed',
    isAdvanced: true,
    data: (stuff) => ['false'].join('%%^^%%'),
    getClientLocation: true
  },
  {
    id: 'reloadApplication',
    type: 'reloadApplication',
    name: 'Reload flow',
    isAdvanced: true,
    data: (stuff) => [].join('%%^^%%'),
  },
  {
    id: 'focusToNextStep',
    type: 'focusToNextStep',
    name: 'Run "Forward / back"',
    isAdvanced: true,
    data: (stuff) => [''].join('%%^^%%')
  },
  {
    id: '',
    name: 'None',
    isAdvanced: true,
    data: (stuff) => [''].join('%%^^%%')
  }
]

const COMPARATORS = {
  number: [
    { id: '>', name: 'is more than' },
    { id: '<', name: 'is less than' },
    { id: '==', name: 'is' },
    { id: '!=', name: 'is not' },
    { id: '>=', name: 'is more than or exactly' },
    { id: '<=', name: 'is less than or exactly' },
    { id: '%', name: 'is a multiple of' }
  ]
}

const TYPE_TO_COMPONENT = new Map([
  [
    '---',
    ({ stuff, value, label, onChange, onIsValid }) => {
      return null
    }
  ],
  [
    'thing',
    ({ stuff, value, label, onChange, onIsValid }) => {
      if (stuff.things.length === 0) {
        return <Banner><p>You need to have a sticky.</p></Banner>
      }
      return (
        <>
          {label && <Label>{label}</Label>}
          <ThingChooser
            applicableEntities={stuff.things}
            selected={value}
            onChange={onChange}
          />
        </>
      )
    }
  ],
  [
    'things',
    ({ stuff, value, label, onChange, onIsValid }) => {
      if (stuff.things.length === 0) {
        return <Banner><p>You need to have a sticky.</p></Banner>
      }
      const forPs = stuff.things
        .map(p => {
          return {
            id: p.id,
            name: p.name
          }
        })
      return (
        <>
          {label && <Label>{label}</Label>}
          <PatchableSetList
            all={forPs}
            set={window.sticky.newPatchableSet(value)}
            onUpdate={set => onChange(set.toArray())}
          />
        </>
      )
    }
  ],
  [
    'application',
    ({ stuff, value, label, onChange, onIsValid }) => {
      if (stuff.applications.length === 0) {
        return <Banner><p>You need to add a flow.</p></Banner>
      }
      return (
        <>
          {label && <Label>{label}</Label>}
          <ApplicationChooser
            applicableEntities={stuff.applications}
            selected={[value]}
            onChange={([id]) => onChange(id)}
          />
          {stuff.user.can('list-applications') && <Input
            label='Or by name'
            onChange={onChange}
            value={!isUuid(value) ? value : ''}
          />}
        </>
      )
    }
  ],
  [
    'application--none',
    ({ stuff, value, label, onChange, onIsValid }) => {
      let [applicationId] = (value || '').split('%%^^%%')
      const doChange = () => {
        onChange([applicationId].join('%%^^%%'))
      }
      return (
        <>
          {label && <Label>{label}</Label>}
          <ApplicationChooser
            applicableEntities={stuff.applications}
            selected={[applicationId]}
            onChange={([id]) => {
              applicationId = id
              doChange()
            }}
            allowNone='(None)'
          />
        </>
      )
    }
  ],
  [
    'focusToNextStep',
    ({ value, label, onChange, onIsValid }) => {
      let [index] = value.split('%%^^%%')
      return (
        <Input
          value={index}
          label='Which? (blank for next)'
          onChange={v => onChange([v])}
        />
      )
    }
  ],
  [
    'blockNextStep',
    ({ value, label, onChange, onIsValid }) => {
      let [hidePreviousSteps] = value.split('%%^^%%')
      hidePreviousSteps = hidePreviousSteps === 'true'
      return (
        <Switch
          checked={hidePreviousSteps}
          onChange={v => {
            hidePreviousSteps = v
            onChange([hidePreviousSteps ? 'true' : 'false'].join('%%^^%%'))
          }}
        >
          Hide previous steps?
        </Switch>
      )
    }
  ],
  [
    'application--none-todo',
    ({ stuff, value, label, onChange, onIsValid }) => {
      let [applicationId, createAToDo = 'false', toDoText = '', toDoColour = '#2f3542', applicationIdByName = '', whichForwardBack = ''] = value.split('%%^^%%')
      createAToDo = createAToDo === 'true' ? true : false
      const doChange = () => {
        onChange([applicationId, createAToDo ? 'true' : 'false', toDoText, toDoColour, applicationIdByName || undefined, whichForwardBack || undefined].join('%%^^%%'))
      }
      const showChooser = typeof applicationId === 'string' && (isUuid(applicationId) || applicationId.startsWith('well-known--go-to-'))
      return (
        <>
          <Label>Then...</Label>
          <Button className='none' inlineIconString={window.sticky.internals.icons.get('hand')} isSecondary turnedOn={applicationId === '' && '#26de81'} onClick={() => { applicationId = ''; doChange() }}>Say thank you</Button>
          <Button className='none' icon={icons.generic.refresh} isSecondary turnedOn={applicationId === 'well-known--reload' && '#26de81'} onClick={() => { applicationId = 'well-known--reload'; doChange() }}>Reload flow</Button>
          <Button
            className='none'
            InlineIcon={dashboardIcons.expand}
            isSecondary
            turnedOn={typeof applicationId === 'string' && isUuid(applicationId) && '#26de81'}
            onClick={() => {
              applicationId = stuff.applications.map(a => a.id).includes(applicationId) ? applicationId : stuff.applications[0].id
              doChange()
            }}
          >
            Pop up flow
          </Button>
          <Button
            className='none'
            InlineIcon={dashboardIcons.application}
            isSecondary
            turnedOn={typeof applicationId === 'string' && applicationId.startsWith('well-known--go-to-') && '#26de81'}
            onClick={() => {
              const maybeOldApplicationId = applicationId.startsWith('well-known--go-to-') && applicationId.substring('well-known--go-to-'.length)
              applicationId = `well-known--go-to-${stuff.applications.map(a => a.id).includes(maybeOldApplicationId) ? maybeOldApplicationId : stuff.applications[0].id}`
              doChange()
            }}
          >
            Go to flow
          </Button>
          <Button
            className='none'
            isSecondary
            turnedOn={applicationId === 'well-known--focusToNextStep' && '#26de81'}
            onClick={() => {
              applicationId = 'well-known--focusToNextStep'
              doChange()
            }}
          >
            Run "Forward / back"
          </Button>
          {applicationId === 'well-known--focusToNextStep' && <Input
            value={whichForwardBack}
            label='Which? (blank for next)'
            onChange={v => {
              whichForwardBack = v
              doChange()
            }}
          />}
          {stuff.user.can('list-applications') && <Button className='none' inlineIconString={window.sticky.internals.icons.get('arrowLeft')} isSecondary turnedOn={applicationId === 'redirectApplicationPrevious' && '#26de81'} onClick={() => { applicationId = 'redirectApplicationPrevious'; doChange() }} color='#DD2E44'>Previous flow</Button>}
          {stuff.user.can('list-applications') && <Button className='none' inlineIconString={window.sticky.internals.icons.get('arrowRight')} isSecondary turnedOn={applicationId === 'redirectApplicationNext' && '#26de81'} onClick={() => { applicationId = 'redirectApplicationNext'; doChange() }} color='#DD2E44'>Next flow</Button>}
          {showChooser && <ApplicationChooser
            applicableEntities={stuff.applications}
            selected={(() => {
              if (applicationIdByName) {
                return []
              }
              const r = (typeof applicationId === 'string' && applicationId.startsWith('well-known--go-to-')) ? applicationId.substring('well-known--go-to-'.length) : applicationId
              return r ? [r] : []
            })()}
            onChange={([v]) => {
              applicationId = typeof applicationId === 'string' && applicationId.startsWith('well-known--go-to-') ? `well-known--go-to-${v}` : v
              doChange()
            }}
          />}
          {showChooser && stuff.user.can('list-applications') && <Input
            label='Or by name'
            onChange={v => {
              applicationIdByName = v
              doChange()
            }}
            value={applicationIdByName}
          />}
          <hr />
          <Switch
            checked={createAToDo}
            onChange={v => {
              createAToDo = v
              doChange()
            }}
          >
            Create a to do?
          </Switch>
          {createAToDo && (
            <>
              <Input
                label='To do'
                value={toDoText}
                onChange={v => {
                  toDoText = v
                  doChange()
                }}
              />
              <ColourPicker label='To do colour' columns={4} cellSize={1.5} colours={[
                '#feca57', '#ff9f43', '#ff6b6b', '#ee5253',
                '#FF00FF', '#5f27cd', '#0abde3', '#4b7bec',
                '#2ed573', '#009432', '#57606f', '#2f3542'
              ]} currentColour={toDoColour} onChoose={v => {
                toDoColour = v
                doChange()
              }} />
            </>
          )}
        </>
      )
    }
  ],
  [
    'application--all',
    ({ stuff, value, label, onChange, onIsValid }) => {
      return (
        <>
          {label && <Label>{label}</Label>}
          <Button className='none' isSecondary turnedOn={value === '' && '#26de81'} onClick={() => { onChange('') }}>(Any flow)</Button>
          <ApplicationChooser
            applicableEntities={stuff.applications}
            selected={[value]}
            onChange={([id]) => onChange(id)}
          />
        </>
      )
    }
  ],
  [
    'applications',
    ({ stuff, value, label, onChange, onIsValid }) => {
      return (
        <>
          {label && <Label>{label}</Label>}
          <ApplicationChooser
            applicableEntities={stuff.applications}
            selected={value}
            onChange={onChange}
            limit={null}
          />
        </>
      )
    }
  ],
  [
    'tags',
    ({ stuff, value, label, onChange, onIsValid }) => {
      return (
        <>
          {label && <Label>{label}</Label>}
          <TagChooserUser
            user={stuff.user}
            tags={window.sticky.newPatchableSet(value)}
            canManage={false}
            onUpdate={(id) => {
              if (value.includes(id)) {
                value = value.filter(_ => _ !== id)
              } else {
                value.push(id)
              }
              onChange(value)
            }}
          />
        </>
      )
    }
  ],
  [
    'product',
    ({ stuff, value, label, onChange, onIsValid }) => {
      if (stuff.products.length === 0) {
        return <Banner><p>You need to add a product.</p></Banner>
      }
      return (
        <Dropdown
          label={label}
          selected={value}
          items={stuff.products}
          onChoose={onChange}
        />
      )
    }
  ],
  [
    'product-category',
    ({ stuff, value, label, onChange, onIsValid }) => {
      if (stuff.productCategories.length === 0) {
        return <Banner><p>You need to add a product category.</p></Banner>
      }
      return (
        <Dropdown
          label={label}
          selected={value}
          items={stuff.productCategories}
          onChoose={onChange}
        />
      )
    }
  ],
  [
    'product-categories',
    ({ stuff, value, label, onChange, onIsValid }) => {
      if (stuff.productCategories.length === 0) {
        return <Banner><p>You need to add a product category.</p></Banner>
      }
      const forPs = stuff.productCategories
        .map(p => {
          return {
            id: p.id,
            name: p.name
          }
        })
      return (
        <PatchableSetList
          all={forPs}
          set={window.sticky.newPatchableSet(value)}
          onUpdate={set => onChange(set.toArray())}
        />
      )
    }
  ],
  [
    'product-optional',
    ({ stuff, value, label, onChange, onIsValid }) => {
      return (
        <Dropdown
          label={label}
          selected={value}
          items={[
            {
              id: '',
              name: '(None)'
            },
            ...stuff.products
          ]}
          onChoose={onChange}
        />
      )
    }
  ],
  [
    'time',
    ({ stuff, value, label, onChange, onIsValid, ...props }) => (
      <TimePicker time={value} timezone={stuff.user.timezone} label={label} onChange={onChange} onIsValid={onIsValid} {...props} />
    )
  ],
  [
    'time--no-limit',
    ({ stuff, value, label, onChange, onIsValid, ...props }) => (
      <TimePicker hourMax={null} time={value} timezone={0} label={label} onChange={onChange} onIsValid={onIsValid} {...props} />
    )
  ],
  [
    'string',
    ({ value, label, color, onChange, ...props }) => (
      <Input value={value} label={label} color={color} onChange={onChange} {...props} />
    )
  ],
  [
    'string-multiline',
    ({ value, label, onChange, ...props }) => (
      <Input type='textarea' value={value} label={label} onChange={onChange} {...props} />
    )
  ],
  [
    'code',
    ({ value, label, onChange, ...props }) => (
      <CodeEditor2 code={value} label={label} controls={[]} onChange={onChange} {...props} />
    )
  ],
  [
    'number',
    ({ value, label, onChange, ...props }) => (
      <Input
        value={typeof value === 'number' ? value.toString() : value}
        label={label}
        onChange={newValue1 => {
          const newValue2 = parseInt(newValue1, 10)
          onChange(!isNaN(newValue2) ? newValue2 : newValue1)
        }}
        {...props}
      />
    )
  ],
  [
    'date',
    ({ value, label, onChange, ...props }) => (
      <DatePicker time={value} label={label} onChange={onChange} {...props} />
    )
  ],
  [
    'price',
    ({ value, label, onChange, ...props }) => (
      <PriceInput value={value} label={label} onChange={onChange} {...props} />
    )
  ],
  [
    'currency',
    ({ value, label, onChange, ...props }) => (
      <Dropdown
        label={label}
        items={window.sticky.Stickypay.CURRENCIES.map(c => ({ id: c.id, name: c.id }))}
        selected={value}
        onChoose={onChange}
      />
    )
  ],
  [
    'url',
    ({ value, label, onChange, onIsValid, ...props }) => (
      <UrlInput value={value} label={label} onChange={onChange} onIsValid={onIsValid} {...props} />
    )
  ],
  [
    'url--optional',
    ({ value, label, onChange, onIsValid, ...props }) => (
      <UrlInput value={value} label={label} onChange={onChange} onIsValid={onIsValid} doValidate={false} {...props} />
    )
  ],
  [
    'uploadImage',
    ({ value, label, onChange, onIsValid, ...props }) => (
      <Box>
        {label && <Label>{label}</Label>}
        <UploadDynamic
          type='image'
          show
          value={value}
          onChange={({ url }) => onChange(url)}
          {...props}
        />
      </Box>
    )
  ],
  [
    'uploadVideo',
    ({ value, label, onChange, onIsValid, ...props }) => (
      <>
        <UploadDynamic
          type='video'
          show
          value={value}
          onChange={({ url }) => onChange(url)}
          {...props}
        />
      </>
    )
  ],
  [
    'uploadCompressedVideo',
    ({ value, label, onChange, onIsValid, ...props }) => (
      <>
        <UploadCompressedVideo
          type='video'
          show
          value={value}
          onChange={({ url }) => onChange(url)}
          {...props}
        />
      </>
    )
  ],
  [
    'uploadDocument',
    ({ value, label, onChange, onIsValid, ...props }) => (
      <>
        <UploadDynamic
          type='document'
          show
          value={value}
          onChange={({ url }) => onChange(url)}
          {...props}
        />
      </>
    )
  ],
  [
    'uploadImage--choose',
    ({ value, label, onChange, onChangeOthers, onIsValid, wellKnownDefaults, ...props }) => (
      <>
        {label && <Label>{label}</Label>}
        <ChooseResource
          type='image'
          backgroundColor={wellKnownDefaults.colour}
          categories={['Seasonal', 'Items', 'Characters']}
          onChoose={({ name, url }) => {
            onChangeOthers({ name, url })
          }}
        />
        <ExpandCollapse
          text='Upload your own'
          isCollapsed
        >
          <UploadDynamic
            type='image'
            show
            value={value}
            onChange={({ url }) => onChange(url)}
            {...props}
          />
        </ExpandCollapse>
      </>
    )
  ],
  [
    'uploadAudio',
    ({ value, label, onChange, configAll, onIsValid, ...props }) => (
      <>
        <UploadDynamic
          type='audio'
          show
          value={value}
          onChange={({ url }) => onChange(url)}
          abProps={configAll}
          {...props}
        />
      </>
    )
  ],
  [
    'boolean',
    ({ value, label, onChange }) => (
      <Switch
        checked={value}
        onChange={onChange}
      >
        {label}
      </Switch>
    )
  ],
  [
    'options',
    ({ value, label, onChange, options }) => (
      <Dropdown
        label={label}
        selected={value}
        items={options
          .map(o => ({
            id: o,
            name: (() => {
              let s = o
              if (s === '---') {
                return '──────────'
              }
              if (s.startsWith('---')) {
                s = s.substring('---'.length)
              }
              return s
            })(),
            disabled: o.startsWith('---')
          }))
        }
        onChoose={onChange}
      />
    )
  ],
  [
    'days',
    ({ value, label, onChange, options, ...props }) => (
      <DayPicker
        turnedOnColor='#26de81'
        label={label}
        days={value}
        onChange={onChange}
        {...props}
      />
    )
  ],
  [
    'array',
    ({ value, lcKey, label, onChange, ...props }) => (
      <>
        {label && <Label>{label}</Label>}
        <ListEditor
          array={value}
          entityName='Item'
          customWhyKey={lcKey}
          onChange={array => onChange(array)}
          fixedHeight={null}
          {...props}
        />
      </>
    )
  ],
  [
    'colour',
    ({ value, label, onChange, wellKnownDefaults }) => (
      <ColourPicker currentColour={value} label={label} onChoose={onChange} />
    )
  ],
  [
    'federated-user',
    ({ stuff, value, label, onChange }) => (
      <Box>
        {label && <Label>{label}</Label>}
        <div className='grid'>
          <Button className='none' isSecondary turnedOn={value === 'none' && '#26de81'} onClick={() => onChange('none')}>(Anyone)</Button>
        </div>
        <FederatedUsersList
          federatedUsers={stuff.federatedUsers}
          onChoose={(e) => onChange(e.id)}
          selected={value}
          linkOut={false}
          showTags={false}
        />
      </Box>
    )
  ],
  [
    'icon',
    ({ value, configAll, label, onChange }) => {
      const items = [
        { id: '', name: '' },
        ...window.sticky.internals.icons.allNoSocialMedia.filter(_ => !_.isHidden).map(_ => ({ ..._, name: undefined, hint: _.name })),
        value.startsWith('https://') && ({ id: value, inlineIconString: window.sticky.internals.icons.get(value), name: undefined, hint: 'My own', backgroundColor: configAll.colour })
      ]
        .filter(_ => _)
      return (
        <>
          <ButtonList
            label={label}
            turnedOnColor='#26de81'
            items={items}
            selected={value}
            onChoose={onChange}
            columns={5}
            padding={0.5}
            className='icons'
          />
          <div className='button-list'>
            <Button
              isSecondary
              InlineIcon={dashboardIcons.upload}
              onClick={async () => {
                const r = await window.sticky.applications.blocks.renderInlineEventsButton(
                  [
                    {
                      'id': '4e6f993d-ddc9-43ea-aadb-7080bee647bc',
                      'config': {
                        'label': '',
                        'buttonText': 'Choose a file',
                        'required': true
                      }
                    }
                  ],
                  'Use'
                )
                r && onChange(r[''])
              }}
            >
              Upload my own...
            </Button>
          </div>
        </>
      )
    }
  ],
  [
    'language--phone',
    ({ value, label, onChange }) => {
      // ISO CODE
      // ENGLISH NAME
      // NATIVE NAME
      // "HOW TO RESET"
      // ICON
      // RIGHT TO LEFT
      // CALLABLE
      const languages = window.sticky.internals.ISO_LANGUAGES
        .filter(([, , , , , , isCallable]) => isCallable)
        .map(([code, englishName, nativeName]) => (
          { id: code, name: nativeName !== englishName ? `${nativeName} (${englishName})` : nativeName }
        ))
      return (
        <Dropdown
          label={label}
          selected={value}
          items={languages}
          onChoose={onChange}
        />
      )
    }
  ],
  [
    'language--complex',
    ({ value, label, onChange }) => {
      const languages = window.sticky.internals.ISO_LANGUAGES
        .map(([id]) => (
          { id, name: window.sticky.internals.languages.getName(id) }
        ))
      let [realValue, setString = ''] = value.split('--')
      const set = window.sticky.newPatchableSet(setString.split(','))
      const setLanguages = languages.filter(l => set.has(l.id))
      const doChange = () => {
        onChange([realValue, set.toArray().join(',')].join('--'))
      }
      return (
        <>
          <Label>of possible languages...</Label>
          <PatchableSetList
            all={languages}
            set={set}
            onUpdate={doChange}
            limitHeight={284}
          />
          <Dropdown
            label='is'
            selected={realValue}
            items={setLanguages}
            onChoose={v => { realValue = v; doChange() }}
          />
        </>
      )
    }
  ],
  [
    'comparator',
    ({ value, label, onChange }) => (
      <Dropdown
        selected={value}
        label={label}
        items={COMPARATORS.number}
        onChoose={onChange}
      />
    )
  ],
  [
    'comparator--time',
    ({ value, label, onChange }) => (
      <div className='grid grid-2'>
        <Button isSecondary turnedOn={value === '<' && '#26de81'} onClick={() => onChange('<')}>Before</Button>
        <Button isSecondary turnedOn={value === '>=' && '#26de81'} onClick={() => onChange('>=')}>After</Button>
        <Button isSecondary turnedOn={value === '==' && '#26de81'} onClick={() => onChange('==')}>Is</Button>
        <Button isSecondary turnedOn={value === '!=' && '#26de81'} onClick={() => onChange('!=')}>Is not</Button>
      </div>
    )
  ],
  [
    'operator',
    ({ value, label, onChange }) => (
      <>
        <Label>{label}</Label>
        <div className='grid grid-4'>
          <Button isSecondary turnedOn={value === '+' && '#26de81'} onClick={() => onChange('+')} icon={icons.generic.operators.plus} />
          <Button isSecondary turnedOn={value === '-' && '#26de81'} onClick={() => onChange('-')} icon={icons.generic.operators.minus} />
          <Button isSecondary turnedOn={value === '*' && '#26de81'} onClick={() => onChange('*')} icon={icons.generic.operators.multiply} />
          <Button isSecondary turnedOn={value === '/' && '#26de81'} onClick={() => onChange('/')} icon={icons.generic.operators.divide} />
        </div>
      </>
    )
  ],
  [
    'value--number',
    ({ stuff, configAll, value, label, onChange, onChangeOthers }) => {
      const [mode, scope, variable] = value
      return (
        <Box>
          {label && <Label>{label}</Label>}
          <div className='button-list'>
            <Button isSecondary turnedOn={mode === 'EVENT_COUNT--SESSION_READ' && '#26de81'} InlineIcon={dashboardIcons.thing} onClick={() => onChange(['EVENT_COUNT--SESSION_READ', 'application-session-type', ''])}>Interactions</Button>
            <Button isSecondary turnedOn={mode === 'EVENT_COUNT--CHECK_IN' && '#26de81'} InlineIcon={dashboardIcons.location} onClick={() => onChange(['EVENT_COUNT--CHECK_IN', 'application-session-type', ''])}>Check ins</Button>
            <hr />
            <Button isSecondary turnedOn={mode === 'EVENT_COUNT--SESSION_CART_PAY' && '#26de81'} InlineIcon={dashboardIcons.payment} onClick={() => onChange(['EVENT_COUNT--SESSION_CART_PAY', 'application-session-type', ''])}>Number of payments</Button>
            <Button isSecondary turnedOn={mode === 'paymentsTotal' && '#26de81'} InlineIcon={dashboardIcons.payment} onClick={() => onChange(['paymentsTotal', 'application-session-type', ''])}>Total spent</Button>
            <hr />
            <Button isSecondary turnedOn={mode === 'variable' && '#26de81'} InlineIcon={dashboardIcons.storeData} onClick={() => onChange(['variable', 'user', ''])}>Something remembered...</Button>
          </div>
          {mode === 'variable' && (
            <>
              <Input
                label='Value'
                value={variable}
                onChange={v => onChange(['variable', scope, v])}
              />
              <SectorScope
                label='For'
                stuff={stuff}
                value={scope}
                onChange={v => onChange(['variable', v, variable])}
              />
            </>
          )}
          {(mode.startsWith('EVENT_COUNT--') || ['paymentsTotal'].includes(mode)) && (
            <EventScope
              label='For'
              stuff={stuff}
              value={scope}
              onChange={v => onChange([mode, v, variable])}
            />
          )}
        </Box>
      )
    }
  ],
  [
    'align',
    ({ value, label, onChange }) => {
      return (
        <>
          {label && <Label>{label}</Label>}
          <div className='grid grid-4'>
            <Button isSecondary
              icon={icons.align.left}
              turnedOn={value === 'left' && '#26de81'}
              onClick={() => { align = 'left'; onChange('left') }}
              hint='Align: left'
            />
            <Button isSecondary
              icon={icons.align.center}
              turnedOn={value === 'center' && '#26de81'}
              onClick={() => { onChange('center') }}
              hint='Align: center'
            />
            <Button isSecondary
              icon={icons.align.right}
              turnedOn={value === 'right' && '#26de81'}
              onClick={() => { onChange('right') }}
              hint='Align: right'
            />
            <Button isSecondary
              icon={icons.align.justify}
              turnedOn={value === 'justify' && '#26de81'}
              onClick={() => { onChange('justify') }}
              hint='Align: justify'
            />
          </div>
        </>
      )
    }
  ],
  [
    'key-value-pairs',
    ({ value, label, stuff, onChange }) => {
      return (
        <>
          {label && <Label>{label}</Label>}
          <KeyValuePairs
            user={stuff.user}
            object={value}
            actionText='Row'
            newDefaults={['Name', 'Song']}
            onUpdate={({ name, value: newValue }) => {
              if (newValue === null) {
                delete value[name]
              } else {
                value[name] = newValue
              }
              onChange(value)
            }}
          />
        </>
      )
    }
  ],
  [
    'font',
    ({ value, onChange }) => {
      let [colour, align, size, inABox] = value.split('--')
      inABox = inABox === 'true'
      const onDone = () => onChange([colour, align, size, inABox ? 'true' : 'false'].join('--'))
      return (
        <Box className='font'>
          <div>
            <Label>Align</Label>
            <div className='grid grid-3'>
              <Button isSecondary
                icon={icons.align.left}
                turnedOn={align === 'left' && '#26de81'}
                onClick={() => { align = 'left'; onDone() }}
                hint='Align: left'
              />
              <Button isSecondary
                icon={icons.align.justify}
                turnedOn={align === 'justify' && '#26de81'}
                onClick={() => { align = 'justify'; onDone() }}
                hint='Align: justify'
              />
              <Button isSecondary
                icon={icons.align.center}
                turnedOn={align === 'center' && '#26de81'}
                onClick={() => { align = 'center'; onDone() }}
                hint='Align: center'
              />
            </div>
          </div>
          <div>
            <Label>Size</Label>
            <div className='grid grid-2 squeeze'>
              <Button isSecondary
                style={{ fontSize: '70%' }}
                turnedOn={size === '70%' && '#26de81'}
                onClick={() => { size = '70%'; onDone() }}
              >Small</Button>
              <Button isSecondary
                style={{ fontSize: '80%' }}
                turnedOn={size === '100%' && '#26de81'}
                onClick={() => { size = '100%'; onDone() }}
              >Regular</Button>
              <Button isSecondary
                style={{ fontSize: '90%' }}
                turnedOn={size === '120%' && '#26de81'}
                onClick={() => { size = '120%'; onDone() }}
              >Large</Button>
              <Button isSecondary
                style={{ fontSize: '100%' }}
                turnedOn={size === '150%' && '#26de81'}
                onClick={() => { size = '150%'; onDone() }}
              >Extra large</Button>
            </div>
          </div>
          <ColourPicker
            currentColour={colour}
            label='Colour'
            onChoose={v => {
              colour = v
              onDone()
            }}
          />
          <Switch
            checked={inABox}
            onChange={v => {
              inABox = v
              onDone()
            }}
          >
            Show in a box?
          </Switch>
        </Box>
      )
    }
  ],
  [
    'sector-scope---user--cross-user--application',
    ({ stuff, value, label, onChange }) => (
      <SectorScope
        stuff={stuff}
        label={label}
        value={value}
        onChange={onChange}
      />
    )
  ],
  // no flow steps use this right now
  // [
  //   'sector-scope---user--cross-user',
  //   ({ stuff, value, label, onChange }) => (
  //     <>
  //       <Label>{label}</Label>
  //       <div className='button-list grid grid-2'>
  //         <Button
  //           isSecondary
  //           turnedOn={value === 'user' && '#26de81'}
  //           onClick={() => onChange('user')}
  //         >
  //           All my flows
  //         </Button>
  //         <Button
  //           isSecondary
  //           turnedOn={value === 'cross-user' && '#26de81'}
  //           onClick={() => onChange('cross-user')}
  //         >
  //           Every flow
  //         </Button>
  //       </div>
  //       {value === 'user' && <Banner mood='good'><p>Every flow for {stuff.user.name} will have the same value.</p></Banner>}
  //       {value === 'cross-user' && <Banner mood='bad'><p>Every flow in the world will have the same value.</p></Banner>}
  //     </>
  //   )
  // ]
  [
    'event-scope',
    ({ stuff, value, label, onChange }) => (
      <EventScope
        label={label}
        stuff={stuff}
        value={value}
        onChange={onChange}
      />
    )
  ],
  [
    'action',
    ({ stuff, value, label, onChange, configAll, configLegacy, onChangeOthers, onIsValid }) => {
      let [action, data, doGetClientLocation] = value.split('~~||~~')
      doGetClientLocation = doGetClientLocation === 'true' ? true : false
      const onReallyChange = (action, data) => onChange(`${action}~~||~~${data}~~||~~${doGetClientLocation ? 'true' : 'false'}`)
      const foundAction = ACTIONS.find(a => a.id === action)
      const Component = TYPE_TO_COMPONENT.get(foundAction.type)
      const actionsProps = {
        stuff,
        doGetClientLocation,
        onChangeOthers
      }
      return (
        <>
          <Box className='action'>
            <Label>Action</Label>
            <Actions
              action={action}
              actions={ACTIONS.filter(_ => {
                if (_.isAdvanced) {
                  return false
                }
                if (typeof _.canShow === 'function') {
                  return _.canShow(stuff.user)
                }
                return true
              })}
              {...actionsProps}
            />
            <ExpandCollapse
              text='Advanced'
              className='advanced'
              isCollapsed={!foundAction.isAdvanced}
            >
              <Actions
                action={action}
                columns={1}
                actions={ACTIONS.filter(_ => {
                  if (!_.isAdvanced) {
                    return false
                  }
                  if (typeof _.canShow === 'function') {
                    return _.canShow(stuff.user)
                  }
                  return true
                })}
                {...actionsProps}
              />
            </ExpandCollapse>
            {Component && <Component
              configLegacy={configLegacy}
              configAll={configAll}
              stuff={stuff}
              key={`component--${action}`}
              value={data}
              onChange={v => onReallyChange(action, v)}
              onChangeOthers={onChangeOthers}
              onIsValid={onIsValid}
              onDone={() => onReallyChange(action, data)}
            />}
          </Box>
          {foundAction.getClientLocation && (
            <Switch
              checked={doGetClientLocation}
              onChange={v => {
                doGetClientLocation = v
                onReallyChange(action, data)
              }}
            >Get phone location</Switch>
          )}
        </>
      )
    }
  ],
  [
    'phone',
    ({ value, label, onChange, ...props }) => (
      <DynamicInput type='phone' value={value} label={label} onChange={onChange} {...props} />
    )
  ],
  [
    'email',
    ({ value, label, onChange, ...props }) => (
      <DynamicInput type='email' value={value} label={label} onChange={onChange} {...props} />
    )
  ]
])

const StyledModalConfigure = styled.div`
  .component--button.none {
    width: 100%;
    height: 2rem;
    padding: 0rem;
  }
  .component--button.none + * {
    margin-top: 1rem;
  }
  .component--modal {
    max-width: 360px;
  }
  .component--h1 + * {
    margin-top: 1rem;
  }
  .component--modal > .component--button {
    margin: 2rem auto 0 auto;
  }
  .grid {
    display: grid;
    grid-gap: 1rem;
    > * {
      width: 100% !important;
    }
    > * + * {
      margin-top: 0 !important;
    }
  }
  .grid.grid-2 {
    grid-template-columns: 1fr 1fr;
  }
  .grid.grid-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .grid.grid-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .grid.squeeze {
    .component--button {
      padding: 0.25rem;
    }
  }
  .component--input.type--number input, .component--input.type--price input {
    max-width: 128px;
  }
  .component--box {
    > * + * {
      margin-top: 1rem;
    }
    label + * {
      margin-top: 0.5rem;
    }
  }
  .component--button-list.icons {
    .component--button {
      padding: 0.1rem;
    }
  }
  .description {
    margin-bottom: 1rem;
    white-space: pre-wrap;
    > div {
      padding-top: 0;
    }
    p {
      font-size: 90%;
      line-height: 1.5rem;
    }
  }
  .action {
    .buttons {
      display: grid;
      grid-gap: 1rem;
      .component--button {
        font-size: 90%;
        padding: 0.25rem;
        svg, img {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
    .grid-1 {
    }
    .grid-2 {
      grid-template-columns: 1fr 1fr;
    }
  }
  .button-list {
    .component--button {
      width: 100%;
      font-size: 90%;
    }
    .component--button + .component--button {
      margin-top: 0.5rem;
    }
  }
  .component--button-list + .button-list {
    margin-top: 0.5rem;
  }
  .component--choose-resource + .component--expand-collapse {
    margin-top: 1rem;
  }
  .component--expand-collapse.advanced {
    margin-top: 1rem;
  }
`

function AbstractForm({ autoFocus, abstractConfig, configLegacy, localConfig, stuff, wellKnownDefaults, setLocalConfig, validities, setValidities, onDone }) {
  return (
    <Form autoFocus={autoFocus}>
      {abstractConfig.map((dc, i) => {
        const { type, key, name, color, inABox, options = [] } = dc
        const Component = TYPE_TO_COMPONENT.get(type)
        const onChangeMany = (object) => {
          setLocalConfig({
            ...localConfig,
            ...object
          })
        }
        const onChange = (k, v) => {
          log('[ModalConfigure] [onChange]', { k, v })
          onChangeMany({ [k]: v })
        }
        if (type === 'thing' && !stuff.things.find(p => p.id === localConfig[key]) && validities[i]) {
          if (stuff.things.length === 0) {
            validities[i] = false
            setValidities([...validities])
          } else {
            localConfig[key] = stuff.things[0].id
          }
        }
        if (type === 'application' && localConfig[key] === '') {
          const foundApplication = stuff.applications.find(p => p.id === localConfig[key])
          if (!foundApplication && validities[i]) {
            if (stuff.applications.length === 0) {
              validities[i] = false
              setValidities([...validities])
            } else {
              localConfig[key] = stuff.applications[0].id
            }
          }
        }
        if (type === 'product' && !stuff.products.find(p => p.id === localConfig[key]) && validities[i]) {
          if (stuff.products.length === 0) {
            validities[i] = false
            setValidities([...validities])
          } else {
            localConfig[key] = stuff.products[0].id
          }
        }
        if (type === 'product-category' && !stuff.productCategories.find(p => p.id === localConfig[key]) && validities[i]) {
          if (stuff.productCategories.length === 0) {
            validities[i] = false
            setValidities([...validities])
          } else {
            localConfig[key] = stuff.productCategories[0].id
          }
        }
        const onIsValid = v => {
          log('[ModalConfigure] [onIsValid]', { v })
          if (i < validities.length) {
            validities[i] = v
            setValidities([...validities])
          }
        }
        const componentToRender = <Component
          configLegacy={configLegacy}
          configAll={localConfig}
          stuff={stuff}
          wellKnownDefaults={wellKnownDefaults}
          key={key}
          lcKey={key}
          value={localConfig[key]}
          label={name}
          color={color}
          onChange={v => onChange(key, v)}
          onChangeOthers={onChangeMany}
          options={options}
          onIsValid={onIsValid}
          onDone={() => onDone(localConfig)}
        />
        return inABox ? <Box style={color ? { border: `2px solid ${color}` } : {}}>{componentToRender}</Box> : componentToRender
      })}
    </Form>
  )
}

function ModalConfigure({ applicationBlock, config, configLegacy, wellKnownDefaults, onClose, onDone }) {
  const [localConfig, setLocalConfig] = useState(config)
  const [validities, setValidities] = useState(Object.keys(config).map(_ => true))
  const isValid = validities.every(v => v)
  const [stuff, setStuff] = useState()
  useEffect(() => {
    async function getStuff() {
      setStuff({
        user: await window.sticky.users.get(),
        things: await window.sticky.things.getAll(),
        applications: await window.sticky.applications.getAll(),
        products: await window.sticky.products.getAll(),
        productCategories: await window.sticky.products.categories.getAll(),
        federatedUsers: await window.sticky.users.federated.getAll()
      })
    }
    !stuff && getStuff()
  })
  if (!stuff) {
    return null
  }
  const dc____Advanced = applicationBlock.defaultConfig.filter(_ => !_.isAdvanced)
  const dcAdvanced = applicationBlock.defaultConfig.filter(_ => _.isAdvanced)
  return (
    <StyledModalConfigure>
      <Modal onClose={onClose}>
        {applicationBlock.description && (
          <ExpandCollapse
            className='description'
            text='Help'
            backgroundColor='#c8f7c5'
            color='#88B785'
          >
            <p>{applicationBlock.description}</p>
          </ExpandCollapse>
        )}
        <H1>{applicationBlock.configName}</H1>
        <AbstractForm abstractConfig={dc____Advanced} configLegacy={configLegacy} localConfig={localConfig} stuff={stuff} wellKnownDefaults={wellKnownDefaults} setLocalConfig={setLocalConfig} validities={validities} setValidities={setValidities} onDone={onDone} />
        {dcAdvanced.length > 0 && (
          <ExpandCollapse
            text='Advanced'
            className='advanced'
          >
            <AbstractForm abstractConfig={dcAdvanced} configLegacy={configLegacy} localConfig={localConfig} stuff={stuff} wellKnownDefaults={wellKnownDefaults} setLocalConfig={setLocalConfig} validities={validities} setValidities={setValidities} onDone={onDone} />
          </ExpandCollapse>
        )}
        <Button
          backgroundColor='#26de81'
          icon={icons.inverted.check}
          onClick={() => onDone(localConfig)}
          disabled={!isValid}
        >
          Done
        </Button>
      </Modal>
    </StyledModalConfigure>
  )
}

const StyledModalChoose = styled.div`
  .component--modal {
    max-width: 530px;
    padding: 1rem;
    padding-top: 2.5rem !important;
  }
`

function ModalChoose({ user, applicationBlocks, onChoose, onClose, doDisableIterator }) {
  return (
    <StyledModalChoose>
      <Modal onClose={onClose}>
        <ApplicationBlockChooser user={user} applicationBlocks={applicationBlocks} onChoose={onChoose} doDisableIterator={doDisableIterator} />
      </Modal>
    </StyledModalChoose>
  )
}

function getConfigFromDefaultConfig(user, wellKnownDefaults, defaultConfig, config) {
  const configToReturn = {}
  defaultConfig.forEach(dc => {
    const evalCode = `const user=${JSON.stringify(user.toJson())}; wellKnownDefaults=${JSON.stringify(wellKnownDefaults)}; ${dc.defaultValueFunction}`
    const realDv = typeof dc.defaultValueFunction === 'string' ? window.eval(evalCode) : dc.defaultValue
    configToReturn[dc.key] = config[dc.key] !== undefined ? config[dc.key] : realDv
  })
  return configToReturn
}

export default {
  trigger: ({ user, applicationBlock, wellKnownDefaults, doDisableIterator, config, index, toInsertIndex, setState }) => {
    let newState = {
      APPLICATION_BLOCK_TO_INSERT_INDEX: toInsertIndex,
      APPLICATION_BLOCK_WELL_KNOWN_DEFAULTS: wellKnownDefaults
    }
    if (typeof applicationBlock === 'object') {
      newState = {
        ...newState,
        APPLICATION_BLOCK_CONFIGURE: applicationBlock,
        APPLICATION_BLOCK_CONFIGURE_CONFIG: getConfigFromDefaultConfig(user, wellKnownDefaults, applicationBlock.defaultConfig, config),
        APPLICATION_BLOCK_CONFIGURE_CONFIG_LEGACY: config,
        APPLICATION_BLOCK_GO_BACK: false,
        APPLICATION_BLOCK_INDEX: index
      }
      setState(newState)
    } else {
      newState = {
        ...newState,
        APPLICATION_BLOCK: true,
        APPLICATION_BLOCK_INDEX: undefined,
        APPLICATION_BLOCK_DO_DISABLE_ITERATOR: doDisableIterator
      }
    }
    setState(newState)
  },
  render: function ThisAction({ user, state, setState, entities, subscribe, dispatch }) {
    function add(applicationBlock) {
      const { defaultConfig } = applicationBlock
      if (defaultConfig.length === 0) {
        dispatch('APPLICATION_BLOCK_GOOD', { applicationBlock, config: {}, toInsertIndex: state.APPLICATION_BLOCK_TO_INSERT_INDEX })
        setState({ APPLICATION_BLOCK: undefined })
      } else {
        setState({
          APPLICATION_BLOCK_CONFIGURE: applicationBlock,
          APPLICATION_BLOCK_CONFIGURE_CONFIG: getConfigFromDefaultConfig(user, state.APPLICATION_BLOCK_WELL_KNOWN_DEFAULTS, applicationBlock.defaultConfig, {}),
          APPLICATION_BLOCK_GO_BACK: true
        })
      }
    }
    useEffect(() => {
      const subscriptions = [
        subscribe(
          'APPLICATION_FOR_PAY_GOOD',
          ({ why, extra, paymentId }) => {
          }
        )
      ]
      return () => {
        subscriptions.forEach(s => s())
      }
    })
    const {
      APPLICATION_BLOCK,
      APPLICATION_BLOCK_CONFIGURE,
      APPLICATION_BLOCK_CONFIGURE_CONFIG,
      APPLICATION_BLOCK_CONFIGURE_CONFIG_LEGACY,
      APPLICATION_BLOCK_GO_BACK,
      APPLICATION_BLOCK_INDEX,
      APPLICATION_BLOCK_TO_INSERT_INDEX,
      APPLICATION_BLOCK_WELL_KNOWN_DEFAULTS,
      APPLICATION_BLOCK_DO_DISABLE_ITERATOR
    } = state
    return (
      <>
        {APPLICATION_BLOCK && <ModalChoose
          user={user}
          applicationBlocks={entities.applicationBlocks}
          onChoose={applicationBlock => {
            const abPrice = applicationBlock.price[user.currency]
            const hasBought = user.ownApplicationBlockIds.has(applicationBlock.id)
            if (!hasBought && abPrice > 0) {
              dispatch(
                'APPLICATION_FOR_PAY',
                {
                  why: 'application-block',
                  extra: { applicationBlockId: applicationBlock.id },
                  applicationId: applicationBlock.stickystoreApplicationId,
                  currency: user.currency,
                  total: abPrice
                }
              )
              return
            }
            add(applicationBlock)
          }}
          onClose={() => {
            setState({ APPLICATION_BLOCK: undefined })
          }}
          doDisableIterator={APPLICATION_BLOCK_DO_DISABLE_ITERATOR}
        />}
        {APPLICATION_BLOCK_CONFIGURE && <ModalConfigure
          wellKnownDefaults={APPLICATION_BLOCK_WELL_KNOWN_DEFAULTS}
          applicationBlock={APPLICATION_BLOCK_CONFIGURE}
          config={APPLICATION_BLOCK_CONFIGURE_CONFIG}
          configLegacy={APPLICATION_BLOCK_CONFIGURE_CONFIG_LEGACY}
          onClose={() => {
            if (APPLICATION_BLOCK_GO_BACK) {
              setState({ APPLICATION_BLOCK: APPLICATION_BLOCK_CONFIGURE, APPLICATION_BLOCK_CONFIGURE: undefined })
            } else {
              setState({ APPLICATION_BLOCK: undefined, APPLICATION_BLOCK_CONFIGURE: undefined })
            }
          }}
          onDone={(config) => {
            log('[APPLICATION_BLOCK_CONFIGURE] [onDone]->config', config)
            dispatch('APPLICATION_BLOCK_GOOD', { applicationBlock: APPLICATION_BLOCK_CONFIGURE, config, index: APPLICATION_BLOCK_INDEX, toInsertIndex: APPLICATION_BLOCK_TO_INSERT_INDEX })
            setState({ APPLICATION_BLOCK: undefined, APPLICATION_BLOCK_CONFIGURE: undefined })
          }}
        />}
      </>
    )
  }
}
