import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { icons, Price, CustomHelmet, Loading, Question, Button, TimeDifference } from '@openbox-app-shared'

import Box from '../../../components/Box'
import GenericModal from '../../../components/modals/generic'
import _ from '../../../_'
import dashboardIcons from '../../../icons'

import EposPayments from './components/Payments'
import ProductsV1 from './components/ProductsV1'
import EposCart from './components/Cart'
import EposSummaryPayment from './components/SummaryPaymentV1'

import { log } from '../../../log'
import { dispatch, subscribe } from '../../../redux'
import GtinInput from './components/GtinInput'
import UserCircle from '../../../components/UserCircle'
import ButtonGrid from '../../../components/ButtonGrid'
import CoolCart from '../../../components/CoolCart'

const DO_REFRESH = false // this is borked; caching needs work (Payment toString)
const REFRESH_INTERVAL = 5 * 1000

const DO_LOCK = false
const LOCK_TIMEOUT = 120 * 1000

const StyledRoute = styled.div`
  min-height: 100vh;
  .component--cool-cart {
    position: fixed;
    bottom: 1rem;
    left: unset;
    right: 1rem;
    z-index: 3;
    max-width: min(calc(100vw - 2rem), 388px);
  }
  > aside {
    width: 100%;
    padding: 3.5rem 1rem 1rem 1rem;
    position: relative;
    // background-color: blue;
    .component--box + .component--box, .settings + * {
      margin-top: 1rem;
    }
    .settings {
      width: calc(100% - 2rem);
    }
  }
  .settings {
    position: absolute;
    top: 1rem;
    left: 1rem;
    height: 2.5rem;
    > * {
      float: left;
      height: 2.5rem;
    }
    > * + * {
      margin-left: 0.5rem;
    }
  }
  .applications-wrapper .component--button-grid {
    padding-top: 0;
  }
  @media only screen and (min-width: 632px) {
    .applications-wrapper .component--button-grid {
      padding-top: 1rem;
    }
    > aside {
      float: left;
      padding-right: 0;
      width: 400px;
      .settings {
        width: calc(100% - 1rem);
      }
    }
    > .content {
      margin-left: 420px;
      width: fit-content;
      max-width: 720px;
    }
  }
  .questions {
    .component--modal {
      max-width: 320px;
      .component--question + .component--question {
        margin-top: 1.5rem;
      }
    }
  }
  .component--input-gtin {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    width: 4rem;
    opacity: 0.2;
    // pointer-events: none;
    input {
      padding: 0.25rem;
      font-size: 80%;
      border: 0;
    }
  }
`

const generateKey = (i = '???', productId, questions) => {
  return `${i}---${productId}---${(questions || []).map(q => `${q.question}=${q.answer}`).join('--')}`
}

const getCartMargin = cpCart => {
  if (!cpCart) {
    return '0rem'
  }
  const allLength = (cpCart.reduce((acc, v) => acc + 1 + v.questions.length, 0) * 3) + ((cpCart.length - 1) * 0.125) + 2
  const num = Math.max(allLength, 9)
  log('[Route-view-stickyretail2] [getCartMargin]', { allLength, num })
  return `${num}rem`
}

const getCartProductCount = cpCart => {
  if (!cpCart) {
    return 0
  }
  return cpCart.reduce((acc, v) => acc + v.quantity, 0)
}

export default class Route extends Component {
  constructor () {
    super()
    window.sticky.pay.setQuery('dynamicExternalSticky')
    this.state = {
      currentlyAddingProduct: undefined,
      currentPayment: undefined,
      customTotal: 0,
      filter: ''
    }

    this.GET_INPUT_GOOD = this.GET_INPUT_GOOD.bind(this)

    this.actionSwitchUser = this.actionSwitchUser.bind(this)
    this.actionReload = this.actionReload.bind(this)
    this.actionGtin = this.actionGtin.bind(this)
    this.actionDebug = this.actionDebug.bind(this)

    this.chooseProduct = this.chooseProduct.bind(this)
    this.chooseThing = this.chooseThing.bind(this)
    this.choosePayment = this.choosePayment.bind(this)
    this.addPayment = this.addPayment.bind(this)
    this.removeProduct = this.removeProduct.bind(this)
    this.onAction = this.onAction.bind(this)
    this.addProductByGtin = this.addProductByGtin.bind(this)
  }

  async onPaymentUpdate (customTotal) {
    const { currentPayment } = this.state
    log('[Route-view-stickyretail2] [onPaymentUpdate] 1', { currentPayment, customTotal })
    const cart = currentPayment.cart.get()
    let total = await window.sticky.Stickypay.getTotal(cart)
    if (typeof customTotal === 'number') {
      total += customTotal
    }
    const patchable = {
      currency: await window.sticky.Stickypay.getCurrency(cart),
      total
    }
    log('[Route-view-stickyretail2] [onPaymentUpdate] 2', { cart, patchable, total })
    currentPayment.patch(patchable)
    this.forceUpdate()
  }

  async saveCurrentPayment (extraState = {}) {
    const { currentPayment } = this.state
    log('[Route-view-stickyretail2] [saveCurrentPayment]', { currentPayment })
    await window.sticky.pay.save(currentPayment, false)
    if (typeof extraState !== 'object') {
      return
    }
    if (Object.keys(extraState).length === 0) {
      this.forceUpdate()
    } else {
      return new Promise(resolve => this.setState(extraState, resolve))
    }
  }

  async addProductByGtin (gtin) {
    const foundProduct = this.state.products.find(p => p.gtin === gtin)
    log('[Route-view-stickyretail2] [addProductByGtin]', { products: this.state.products, foundProduct })
    if (!foundProduct) {
      dispatch('GET_INPUT', { why: 'product--gtin', hint: 'Not found; check Barcode:', string: gtin, selectAll: true })
      return
    }
    await this.addProduct(foundProduct, { filter: '' })
  }

  async addProduct (product, extraState = {}) {
    const { currentPayment } = this.state
    log('[Route-view-stickyretail2] [addProduct]', { product, currentPayment: JSON.stringify(currentPayment, null, 2) })
    const customTotal = this.getCustomTotal()
    const cart = currentPayment.cart.get()
    const key = generateKey(undefined, product.id, product.questions)
    const foundCartItem = cart.find(ci => ci.key === key)
    const productPrice = window.sticky.Stickypay.getProductPrice(product, product.questions)
    product.changeStock(-1)
    if (foundCartItem) {
      foundCartItem.quantity += 1
      foundCartItem.productName = product.name
    } else {
      cart.push({
        key,
        product,
        productName: product.name,
        productCurrency: product.currency,
        productTheirId: product.theirId,
        productId: product.id,
        productPrice,
        quantity: 1,
        questions: product.questions.map(q => q.cloneToJson())
      })
    }
    await this.onPaymentUpdate(customTotal)
    await this.saveCurrentPayment(extraState)
    this.doneSomething()
  }

  async removeProduct (whichCi) {
    const customTotal = this.getCustomTotal()
    dispatch('LOADING')
    log('[Route-view-stickyretail2] [removeProduct]', { whichCi, customTotal })
    const { currentPayment } = this.state
    let cart = currentPayment.cart.get()
    whichCi.quantity -= 1
    whichCi.product.changeStock(1)
    if (whichCi.quantity <= 0) {
      log('[Route-view-stickyretail2] [removeProduct] inside if')
      cart = cart.filter(ci => {
        const keepThisOne = ci.key !== whichCi.key
        // log('[Route-view-stickyretail2] [removeProduct] each ci key', ci.key, keepThisOne)
        return keepThisOne
      })
      currentPayment.cart.set(cart)
    }

    await this.onPaymentUpdate(customTotal)
    await this.saveCurrentPayment()

    const eventPayload = {
      type: 'VOID_PRODUCT',
      paymentId: currentPayment.id,
      productId: whichCi.product.id
    }
    await window.sticky.session.createEvent(
      eventPayload,
      'doesnt-matter',
      currentPayment.application && currentPayment.application.id,
      currentPayment.thing && currentPayment.thing.id
    )

    dispatch('STOP_LOADING')
    this.doneSomething()
  }

  async chooseProduct (product) {
    log('[Route-view-stickyretail2] [chooseProduct]', { product })
    if (product.questions.length === 0) {
      this.addProduct(product, { filter: '' })
    } else {
      this.setState({
        currentlyAddingProduct: product
      })
    }
    this.doneSomething()
  }

  saveUserPreferences () {
    const { lastThingId, lastPaymentId, gtinOn } = this.state
    dispatch('SAVE_USER_PREFERENCES', { lastThingId, lastPaymentId, gtinOn })
  }

  async chooseThing (newThing) {
    const { currentThing, currentPayment } = this.state
    if (currentThing === newThing && !currentPayment) {
      this.setState(
        {
          currentThing: undefined,
          currentPayment: undefined,
          lastThingId: undefined
        },
        this.saveUserPreferences
      )
      this.doneSomething()
      return
    }
    this.setState(
      {
        currentThing: newThing,
        currentPayment: undefined,
        lastThingId: newThing ? newThing.id : undefined
      },
      this.saveUserPreferences
    )
    this.doneSomething()
  }

  async choosePayment (payment) {
    log('[Route-view-stickyretail2] [choosePayment]', { payment })
    const currentPayment = payment
    const lastPaymentId = currentPayment ? currentPayment.id : undefined
    this.setState(
      {
        currentPayment,
        lastPaymentId
      },
      this.saveUserPreferences
    )
    this.doneSomething()
  }

  async reallyAddPayment (currentThing, extraProps = {}, changeToCurrentThing) {
    const { currentApplication } = this.state
    const thingId = currentThing ? currentThing.id : undefined
    dispatch('LOADING')
    const payload = {
      thingId,
      ...extraProps
    }
    const currentPayment = await window.sticky.pay.create(currentApplication ? currentApplication.id : undefined, payload)
    const lastPaymentId = currentPayment.id
    const gpParam = {
      currentPayment,
      lastPaymentId,
      selectedPaymentsTab: currentThing ? 'things' : 'payments--unpaid'
    }
    if (changeToCurrentThing) {
      gpParam.currentThing = changeToCurrentThing
    }
    await this.getPayments(gpParam, false)
    this.saveUserPreferences()
    dispatch('STOP_LOADING')
    this.doneSomething()
    return currentPayment
  }

  async addPayment (forceChooseThing) {
    const { currentThing, currentPayment, selectedPaymentsTab, payments, things } = this.state
    if (forceChooseThing || (selectedPaymentsTab === 'things' && !currentPayment && !currentThing)) {
      const thingIds = things
        .filter(t => {
          const paymentsForThisThing = payments.filter(p => {
            if (!p.isUnpaid) {
              return false
            }
            return p.thingId === t.id && !p.newMaster
          })
          return paymentsForThisThing.length === 0
        })
        .map(t => t.id)
      if (thingIds.length === 0) {
        dispatch('SHOW_MESSAGE', { message: <p>All stickies are occupied.</p>, canBeBadded: '' })
        return
      }
      dispatch('CHOOSE_THING', { thingIds, why: 'epos--add-payment', hint: 'Which sticky should this consumer be assigned to?' })
      return
    }
    log('[Route-view-stickyretail2] [addPayment]', { currentThing })
    await this.reallyAddPayment(currentThing, undefined, currentThing)
  }

  async getPayments (extraState = {}, force = true) {
    if (this.isReloading) {
      this.setState(extraState)
      return
    }
    this.isReloading = true

    const newPayments = [...(await window.sticky.pay.getAll(force))].reverse()

    const federatedUsers = await window.sticky.users.federated.getAll()
    const things = await window.sticky.things.getAll()

    const applications = await window.sticky.applications.getAll()

    const applicationsStickyretail2 = applications.filter(a => a.stickyretail.readFrom('showInStickyretail2') === true)
    const applicationsMoto = applications
      .filter(a => a.baseSettingsRender === 'stickypay' && a.stickyretail.readFrom('isMoto'))
    const applicationsNotMoto = applications
      .filter(a => a.baseSettingsRender === 'stickypay' && !a.stickyretail.readFrom('isMoto'))

    const payments = newPayments
      .map(payment => {
        payment.cart.set(
          payment.cart.get()
            .map(ci => {
              ci.key = generateKey(undefined, ci.productId, ci.questions)
              return ci
            })
        )
        return payment
      })
    const products = await window.sticky.products.getAll()
    const productCategories = (await window.sticky.products.getByCategories())
      .filter(pc => pc.isEnabled)
    const state = {
      federatedUsers,
      payments,
      things,
      applicationsMoto,
      applicationsNotMoto,
      applications,
      applicationsStickyretail2,
      products,
      productCategories,
      ...extraState
    }
    return new Promise(resolve => {
      this.setState(
        state,
        () => {
          this.isReloading = false
          resolve()
        }
      )
    })
  }

  postFederatedUser (federatedUser) {
    const state = {
      currentFederatedUser: federatedUser
    }
    this.setState(state)
    this.doneSomething(state)
  }

  async componentDidMount () {
    const { user } = this.props
    this.subscriptions = [
      subscribe('REDUX_HANDLER_READY', () => {
        if (user.federatedUser) {
          this.postFederatedUser(user.federatedUser)
        } else {
          this.switchUser()
        }
      }),
      subscribe(
        'CHOOSE_FEDERATED_USER_GOOD',
        ({ why, federatedUser }) => {
          why === 'stickyretail2' && this.postFederatedUser(federatedUser)
        }
      )
    ]

    const { userPreferences: { gtinOn = false, lastPaymentId, lastThingId } } = this.props

    log('[Route-view-stickyretail2] [componentDidMount]', { gtinOn, lastPaymentId })
    document.querySelector('main').style.maxWidth = 'none'
    document.querySelector('.component--navbar').style.display = 'none'

    window.sticky.bodgeZone.triggerConsumerApp = async (what, args, callback) => {
      const formChecklist = ([masterReference, index, isChecked]) => {
        // coming from "real" flows and steps, not the question modal
        if (typeof masterReference !== 'number') {
          return
        }
        const foundToBeModified = this.state.currentlyAddingProduct.questions[masterReference]
        const toPush = this.state.currentlyAddingProduct.questions[masterReference].options[index].name
        log('[App] [componentDidMount]->triggerConsumerApp->formChecklist 1', { masterReference, index, isChecked, foundToBeModified, toPush, final: foundToBeModified.answer })
        if (isChecked) {
          foundToBeModified.answer = [...foundToBeModified.answer, toPush]
        } else {
          foundToBeModified.answer = foundToBeModified.answer.filter(_ => _ !== toPush)
        }
        this.forceUpdate()
      }
      const formChooser = async ([masterReference, newValue]) => {
        // coming from "real" flows and steps, not the question modal
        if (typeof masterReference !== 'number') {
          return
        }
        const foundToBeModified = this.state.currentlyAddingProduct.questions[masterReference]
        foundToBeModified.answer = newValue
        this.forceUpdate()
      }
      const whatFunction = new Map([
        ['form--checklist', formChecklist],
        ['form--chooser', formChooser]
      ]).get(what)
      const whatFunctionResult = whatFunction ? await whatFunction(args) : undefined
      return whatFunctionResult
    }

    window.sticky.Stickypay.setMinimum(0)
    await this.getPayments(
      {
        gtinOn,
        lastPaymentId,
        lastThingId
      },
      false
    )

    if (DO_REFRESH) {
      this.refreshTimer = setInterval(
        () => {
          this.getPayments()
        },
        REFRESH_INTERVAL
      )
    }

    this.subscriptions = [
      ...this.subscriptions,
      subscribe(
        'CHOOSE_THING_GOOD',
        ({ thing, why }) => {
          if (!thing) {
            return
          }
          why === 'epos--add-payment' && (() => {
            this.reallyAddPayment(thing, undefined, thing)
          })()
        }
      ),
      subscribe('PAYMENT_REFUND_GOOD', ({ why }) => {
        why === 'stickyretail2' && this.getPayments()
      }),
      subscribe('GET_INPUT_GOOD', this.GET_INPUT_GOOD),
      subscribe('DETAILSS_GOOD', () => this.doneSomething()),
      subscribe(
        'PAY_WAIT_ORIGIN_GOOD',
        () => {
          this.getPayments({ currentPayment: undefined, selectedPaymentsTab: 'payments--unpaid' }, true)
        }
      ),
      subscribe(
        'PAY_WAIT_ORIGIN_BAD',
        (...args) => {}
      ),
      subscribe(
        'PAY_WAIT_ORIGIN_CANCEL',
        (...args) => {}
      ),
      subscribe(
        'GET_PRICE_GOOD',
        ({ why, price }) => {
          why === 'epos--custom-total' && (() => {
            this.setCustomTotal(this.state.currentPayment.total + price)
          })()
          why === 'epos--discount' && (() => {
            this.setCustomTotal(this.state.currentPayment.total - price)
          })()
        }
      )
    ]
  }

  actionSwitchUser () {
    this.switchUser(true)
  }

  componentWillUnmount () {
    window.sticky.bodgeZone.triggerConsumerApp = undefined
  }

  async GET_INPUT_GOOD ({ why, string }) {
    why === 'product--gtin' && this.addProductByGtin(string)
    why === 'stickyretail2-email' && (async () => {
      const { currentPayment } = this.state
      currentPayment.patch({ email: string })
      await this.saveCurrentPayment()
      dispatch('TRIGGER', { trigger: 'public--emailPayment', forcePublicKey: true, body: { paymentId: currentPayment.id, sessionId: currentPayment.sessionId } })
    })()
    why === 'stickyretail2-filter' && (async () => {
      this.setState({
        filter: string
      })
    })()
  }

  async componentWillUnmount () {
    log('[Route-view-stickyretail2] [componentWillUnmount]')
    clearInterval(this.refreshTimer)
    clearInterval(this.lockTimer)

    this.subscriptions && this.subscriptions.forEach(s => s())
  }

  doneSomething (state) {
    this.gtinAutoFocus && this.gtinAutoFocus()
    clearInterval(this.lockTimer)
    const finalState = {
      ...this.state,
      state
    }
    const { switchingUser, federatedUsers } = finalState
    if (switchingUser) {
      return
    }
    log('[Route-view-stickyretail2] [doneSomething]', { finalState })
    if (DO_LOCK && federatedUsers.length > 0) {
      this.lockTimer = setTimeout(
        () => {
          this.switchUser()
        },
        LOCK_TIMEOUT
      )
    }
  }

  async onAction (action, payload = {}, doShowLoading = true) {
    let { currentPayment } = this.state
    doShowLoading && dispatch('LOADING')

    await ({
      'to-to-do': async () => {
        const changes = {
          newStatusDone: false
        }
        currentPayment.patch(changes)
        await this.saveCurrentPayment()
      },
      'done': async () => {
        const changes = {
          newStatusDone: true
        }
        currentPayment.patch(changes)
        await this.saveCurrentPayment()
      },
      'pay': async () => {
        const { selectedPaymentsTab, currentThing, payments } = this.state
        const { toDo, thing, newApplicationId } = payload
        let cart
        if (selectedPaymentsTab === 'things' && currentThing && !currentPayment) {
          const foundMasterPayments = payments.filter(p => p.newMaster)
          for (let i = 0; i < foundMasterPayments.length; i++) {
            await window.sticky.pay.remove(foundMasterPayments[i], false)
          }
          const { total, currency, mergedCart, originPaymentIdsDelete } = this.getWillPay(currentThing)
          cart = JSON.stringify(mergedCart)
          currentPayment = await this.reallyAddPayment(thing, { total, currency, cart, originPaymentIdsDelete, newMaster: true }, currentThing)
        }
        const HOW_TOS = {
          'GATEWAY_CASH': {
            patchAndSave: true,
            changes: {
              gateway: 'GATEWAY_CASH',
              sessionPaidAt: Math.floor((+window.sticky.dateTime.getNowUtcLegacy()) / 1000),
              cart
            },
            switchToPaidTab: true
          },
          'GATEWAY_CARD': {
            patchAndSave: true,
            changes: {
              gateway: 'GATEWAY_CARD',
              sessionPaidAt: Math.floor((+window.sticky.dateTime.getNowUtcLegacy()) / 1000),
              cart
            },
            switchToPaidTab: true
          },
          'GATEWAY_KINETIC': {
            patchAndSave: true,
            changes: {
              gateway: 'GATEWAY_KINETIC',
              sessionPaidAt: Math.floor((+window.sticky.dateTime.getNowUtcLegacy()) / 1000),
              cart
            },
            preLogic: async () => {
              const chps = await window.sticky.chp.getAll()
              window.sticky.assert(chps.length > 0, 'You don\'t have any Sticky card machines.')
              const [chp1] = chps
              const paymentGatewayId = await chp1.pay(currentPayment.total)
              return {
                changes: {
                  paymentGatewayId
                }
              }
            },
            switchToPaidTab: true
          },
          'sticky': {
            patchAndSave: true,
            changes: {
              thingId: thing ? thing.id : undefined,
              applicationId: thing ? thing.applicationId : undefined,
              cart,
              newStatusDoneDontSet: true
            },
            thingLogic: async () => {
              thing.customData.writeTo('paymentId', currentPayment.id)
              thing.patch({
                customData: thing.customData.get(),
                applicationIdHotSwap: thing.applicationId,
                applicationId: newApplicationId,
              })
            },
            postLogic: async () => {
              dispatch(
                'PAY_WAIT_ORIGIN',
                {
                  why: 'epos--pay--thing',
                  extra: {
                    howToLinkGeneric: sessionId => window.sticky.things.goTest(thing.id, sessionId, undefined, 'previewLinkCounter=1'),
                    linkImage: window.sticky.things.getImage(thing.id),
                    referenceKey: 'thingId',
                    referenceValue: thing.id,
                    showQrImmediate: thing.isVirtual
                  }
                }
              )
            }
          }
        }

        const foundHowTo = HOW_TOS[toDo]
        const { changes, thingLogic, preLogic, postLogic, switchToPaidTab } = foundHowTo
        log('[Route-view-stickyretail2] [onAction]->pay', { action, currentPayment, changes, toDo, foundHowTo, thing })

        try {
          const preLogicResult = preLogic && await preLogic()
          if (typeof preLogicResult === 'object') {
            const { changes: preLogicChanges } = preLogicResult
            currentPayment.patch(preLogicChanges)
          }
          // preLogic is an exit routine; if it throws don't do anything else!
          thing && thingLogic && await (async () => {
            await thingLogic()
            await window.sticky.things.save(thing, undefined, ['customData', 'applicationId', 'applicationIdHotSwap'])
          })()
          currentPayment.patch(changes)
          const scpDelta = {}
          if (switchToPaidTab) {
            scpDelta.selectedPaymentsTab = 'payments--paid'
          }
          await this.saveCurrentPayment(scpDelta)
          this.saveUserPreferences()
          postLogic && await postLogic()
        } catch (e) {
          dispatch('SHOW_MESSAGE', { message: <><p><strong>Sorry, that didn't work.</strong></p><p>{e.message}</p></>, canBeBadded: '' })
        }
      },
      'move': async () => {
        const { thing } = payload
        log('[Route-view-stickyretail2] [onAction]->move 1', { thing })
        currentPayment.patch(
          {
            thingId: thing.id
          }
        )
        currentPayment.thing = thing
        log('[Route-view-stickyretail2] [onAction]->move 2')
        await this.saveCurrentPayment({})
      },
      'patch': async () => {
        currentPayment.patch({ [payload.k]: payload.v })
        await this.saveCurrentPayment({})
      },
      'extra': async () => {
        currentPayment.extra = payload.string
        await this.saveCurrentPayment({})
      },
      'moto': async () => {
        const { payments, currentPayment, currentThing } = this.state
        const remainingPaymentsForThing = payments.filter(p => p.thingId === currentPayment.thingId)
        const isLastPaymentForThing = remainingPaymentsForThing.length === 1
        await this.getPayments({ currentPayment: undefined, lastPaymentId: undefined, currentThing: isLastPaymentForThing ? undefined : currentThing }, true)
      },
      'void': async () => {
        const { payments, currentPayment, currentThing } = this.state
        if (currentPayment) {
          const remainingPaymentsForThing = payments.filter(p => p.thingId === currentPayment.thingId)
          const isLastPaymentForThing = remainingPaymentsForThing.length === 1
          log('[Route-view-stickyretail2] [onAction]->void', { remainingPaymentsForThing, isLastPaymentForThing })
          try {
            await window.sticky.pay.remove(currentPayment, true)
          } catch ({ message }) {
            return dispatch('SHOW_MESSAGE', { message: <><p><strong>Sorry, that didn't work.</strong></p><p>{message}</p></>, canBeBadded: '' })
          }
          await this.getPayments({ currentPayment: undefined, lastPaymentId: undefined, currentThing: isLastPaymentForThing ? undefined : currentThing }, false)
          return
        }
        if (currentThing) {
          try {
            const remainingPaymentsForThing = payments.filter(p => p.thingId === currentThing.id)
            for (let i = 0; i < remainingPaymentsForThing.length; i++) {
              await window.sticky.pay.remove(remainingPaymentsForThing[i], false)
            }
          } catch ({ message }) {
            return dispatch('SHOW_MESSAGE', { message: <><p><strong>Sorry, that didn't work.</strong></p><p>{message}</p></>, canBeBadded: '' })
          }
          await this.getPayments({ currentPayment: undefined, lastPaymentId: undefined, currentThing: undefined }, false)
          return
        }
      },
      'print': async () => {
        log('[Route-view-stickyretail2] [onAction]->print')
        dispatch('TRIGGER', { trigger: 'printPayment', body: { paymentId: currentPayment.id } })
      },
      'email': async () => {
        dispatch('GET_INPUT', { type: 'email', why: 'stickyretail2-email', hint: 'Choose an email:', string: currentPayment.email || '', selectAll: true })
      }
    })[action]()
    doShowLoading && dispatch('STOP_LOADING')
    this.doneSomething()
  }

  switchUser (canBeClosed) {
    this.setState(
      {
        switchingUser: true
      },
      () => {
        dispatch('CHOOSE_FEDERATED_USER', { why: 'stickyretail2', canBeClosed })
      }
    )
  }

  actionReload () {
    log('[Route-view-stickyretail2] [actionReload]')
    this.getPayments()
  }

  actionGtin () {
    let { gtinOn } = this.state
    gtinOn = !gtinOn
    log('[Route-view-stickyretail2] [actionGtin]', { gtinOn })

    this.setState(
      { gtinOn },
      this.saveUserPreferences
    )
    gtinOn && setTimeout(
      () => {
        this.gtinAutoFocus && this.gtinAutoFocus()
      },
      500
    )
  }

  async actionDebug () {
    const { user } = this.props
    const { currentPayment, applications } = this.state
    dispatch('LOADING')
    const session = currentPayment && await currentPayment.getSession()
    dispatch('STOP_LOADING')

    const currentPaymentApplication = currentPayment && applications.find(a => a.id === currentPayment.applicationId)
    log('[Route-view-stickyretail2] [actionDebug]', { session, currentPaymentApplication })

    const detailss = [
      new Map([
        // ['Manual payments flow → ' + window.sticky._('APPLICATION'), currentApplication ? <>{currentApplication.name} (<code>{currentApplication.id}</code>)</> : 'Unknown']
      ]),

      currentPayment ? new Map([
        ['Payment → ID', <code key='d--id'>{currentPayment.id}</code>],
        ['Payment → Session ID', <code key='d--sessionId'>{currentPayment.sessionId}</code>],
        ['Payment → Type', <code key='d--type'>{currentPayment.type}</code>],
        ['Payment → Time', <TimeDifference key='d--time' time={currentPayment.time} timezone={user.timezone} />],
        ['Payment → Payment provider', <code key='d--gateway'>{currentPayment.gateway}</code>],
        ['Payment → ' + window.sticky._('APPLICATION'), currentPaymentApplication ? <>{currentPaymentApplication.name} (<code>{currentPaymentApplication.id}</code>)</> : 'Unknown'],
        ['Payment → ' + _('THING'), currentPayment.thing ? <>{currentPayment.thing.name} (<code>{currentPayment.thing.id}</code>)</> : 'Unknown']
      ]) : new Map(),

      session ? session.toHumanMap() : new Map()
    ]
    dispatch('DETAILSS', { detailss, canEdit: false })
    this.doneSomething()
  }

  getCustomTotal () {
    const { currentPayment } = this.state
    const rawPaymentTotal = currentPayment.total
    const rawCartTotal = window.sticky.Stickypay.getPreDiscountTotalSync(currentPayment.cart.get())
    log('[Route-view-stickyretail2] [getCustomTotal] rawCartTotal', rawCartTotal)
    log('[Route-view-stickyretail2] [getCustomTotal] currentPayment.total', currentPayment.total)
    return (rawCartTotal !== rawPaymentTotal ? rawPaymentTotal - rawCartTotal : undefined)
  }

  getWillPay (currentThing) {
    const { payments } = this.state
    const paymentsUnpaid = payments.filter(p => {
      return p.thingId === currentThing.id && p.isUnpaid && !p.newMaster
    })
    let mergedCart = []
    paymentsUnpaid.forEach(p => {
      mergedCart = [
        ...mergedCart,
        ...p.cart.get()
      ]
      mergedCart = mergedCart.map((mci, i) => {
        return {
          ...mci,
          key: i
        }
      })
    })
    const total = window.sticky.sum(paymentsUnpaid.map(p => p.total))
    const toReturn = {
      total,
      mergedCart,
      originPaymentIdsDelete: paymentsUnpaid.map(p => p.id)
    }
    const currency = paymentsUnpaid.length > 0 ? paymentsUnpaid[0].currency : undefined
    if (typeof currency === 'string') {
      toReturn.currency = currency
    }
    return toReturn
  }

  render () {
    const { user, userPreferences } = this.props
    const {
      currentFederatedUser,
      payments,
      things,
      applicationsMoto,
      applicationsNotMoto,
      applicationsStickyretail2,
      products,
      productCategories,
      currentlyAddingProduct,
      currentPayment,
      currentThing,
      gtinOn,
      lastPaymentId,
      lastThingId,
      filter,
      selectedPaymentsTab
    } = this.state
    const hasPaid = currentPayment && typeof currentPayment.sessionPaidAt === 'number'
    log('[Route-view-stickyretail2] [render]', { currentPayment, lastPaymentId, lastThingId, filter, productCategories })

    const canRender = [payments, things, productCategories].every(_ => _)
    const willPayAll = selectedPaymentsTab === 'things' && currentThing && !currentPayment ? this.getWillPay(currentThing) : undefined
    const willAddCustomer = (selectedPaymentsTab === 'things' && currentThing) ? true : false
    const addLanguage = (selectedPaymentsTab === 'things' && currentThing) ? 'Add part' : undefined

    function generateCiBodge (key, name, number) {
      return {
        key: `bodge---${key}`,
        productCurrency: currentPayment.currency,
        product: {
          price: Math.abs(number),
          name
        },
        productPrice: Math.abs(number),
        quantity: 1,
        questions: [],
        quantitySymbolOverride: number >= 0 ? '+' : '-'
      }
    }

    const originalCpCart = willPayAll ? willPayAll.mergedCart : (currentPayment && currentPayment.cart.get())
    const cartProductCount = getCartProductCount(originalCpCart)
    let cpCart = originalCpCart
    let customTotal
    if (currentPayment && !currentPayment.newMaster && !willPayAll) {
      customTotal = this.getCustomTotal()
      if (typeof customTotal === 'number') {
        cpCart = [
          ...cpCart,
          generateCiBodge('customTotal', cartProductCount > 0 ? (customTotal >= 0 ? 'Plus' : 'Minus') : 'Total', customTotal)
        ]
      }
    }
    const asideMarginBottom = getCartMargin(cpCart)
    const thePayments = payments || []

    return (
      <StyledRoute
        className='level--styled-route'
        onClick={(e) => {
          if (e.target.classList.contains('level--styled-route')) {
            this.gtinAutoFocus && this.gtinAutoFocus()
          }
        }}
        style={{ paddingBottom: asideMarginBottom }}
      >
        <CustomHelmet
          title='Take a payment'
        />

        {currentlyAddingProduct && (
          <GenericModal
            className='questions'
            onGood={() => {
              this.addProduct(currentlyAddingProduct, { currentlyAddingProduct: undefined })
            }}
            onClose={() => {
              this.setState({
                currentlyAddingProduct: undefined
              })
            }}
          >
            {currentlyAddingProduct.questions.map((q, i) => {
              return (
                <Question
                  key={`${i}-${q.question}`}
                  question={q}
                  masterReference={i}
                  currency={currentlyAddingProduct.currency}
                />
              )
            })}
          </GenericModal>
        )}

        {!canRender && <Loading />}
        {canRender && (
          <>
            {gtinOn && (
              <GtinInput
                onReady={(gtinAutoFocus) => {
                  this.gtinAutoFocus = gtinAutoFocus
                }}
                onInputted={this.addProductByGtin}
              />
            )}
            {cpCart && cpCart.length > 0 && (
              <CoolCart isCollapsedByDefault headline={<><Price price={(willPayAll || { total: currentPayment.total }).total} currency={(willPayAll || { currency: currentPayment.currency }).currency} />{cartProductCount > 0 ? ` | ${cartProductCount} ${cartProductCount !== 1 ? 'products' : 'product'}` : ''}</>}>
                <EposCart
                  cart={cpCart}
                  canVoid={(willPayAll ? false : !hasPaid) ? '#ff3838' : false}
                  onVoid={cartItem => {
                    const handlers = {
                      'bodge---customTotal': () => {
                        this.setCustomTotal(currentPayment.total - customTotal)
                      }
                    }
                    const foundHandler = handlers[cartItem.key]
                    if (foundHandler) {
                      foundHandler()
                    } else {
                      this.removeProduct(cartItem)
                    }
                  }}
                />
              </CoolCart>
            )}
            <aside>
              <div className='settings'>
                {currentFederatedUser && (
                  <UserCircle fixedWidthHeight={2.5} onClick={this.actionSwitchUser} name={currentFederatedUser.name} color={currentFederatedUser.color} photoUrl={currentFederatedUser.photoUrl} />
                )}
                <Button onClick={this.actionReload} icon={icons.generic.refresh} isSecondary />
                {productCategories.length > 0 && <Button
                  onClick={() => dispatch('GET_INPUT', { why: 'stickyretail2-filter', hint: 'What are you looking for?', string: filter, selectAll: true, doValidate: false })}
                  InlineIcon={dashboardIcons.search}
                  isSecondary
                />}
                {productCategories.length > 0 && <Button onClick={this.actionGtin} icon={icons.generic.barcode} isSecondary turnedOn={gtinOn && '#322CBE'} />}
              </div>
              <Box>
                <EposSummaryPayment
                  user={user}
                  userPreferences={userPreferences}
                  currentThing={currentThing}
                  payment={currentPayment}
                  hasThings={things.length > 0}
                  applicationsMoto={applicationsMoto}
                  applicationsNotMoto={applicationsNotMoto}
                  canMove={things.length > 0}
                  onAction={this.onAction}
                  onAddPayment={this.addPayment}
                  addLanguage={addLanguage}
                  willPayAll={willPayAll}
                  willAddCustomer={willAddCustomer}
                  onSetCustomTotal={() => {
                    this.doneSomething()
                    dispatch('GET_PRICE', { why: 'epos--custom-total', price: 0 })
                  }}
                  onDiscount={() => {
                    this.doneSomething()
                    dispatch('GET_PRICE', { why: 'epos--discount', price: 0 })
                  }}
                />
              </Box>
              {thePayments.length > 0 && <Box>
                <EposPayments
                  things={things}
                  user={user}
                  payments={thePayments}
                  currentPayment={currentPayment}
                  currentThing={currentThing}
                  onChooseThing={this.chooseThing}
                  onChoosePayment={this.choosePayment}
                  selectedTab={selectedPaymentsTab}
                  onChangeSelectedTab={selectedPaymentsTab => {
                    const newState = {
                      selectedPaymentsTab,
                      currentThing: undefined,
                      currentPayment: undefined
                    }
                    this.setState(newState)
                  }}
                />
              </Box>}
            </aside>
            <div className='content'>
              {applicationsStickyretail2.length > 0 && (<div className='applications-wrapper'>
                <ButtonGrid>
                  {applicationsStickyretail2.map(a => (
                    <Button
                      key={a.id}
                      icon={a.baseIcon}
                      isSecondary
                      color='white'
                      backgroundColor='#1A1F35'
                      onClick={() => {
                        window.sticky.popUpIframe({
                          src: sticky.applications.test(a.id),
                          maxWidth: '376px',
                          maxHeight: '680px'
                        })
                      }}
                    >
                      {a.name}
                    </Button>
                  ))}
                </ButtonGrid>
              </div>)}
              {currentPayment && !currentPayment.newMaster && currentPayment.isUnpaid && <ProductsV1
                filter={filter}
                productCategories={productCategories}
                onActive={() => {
                  this.doneSomething()
                }}
                onChoose={this.chooseProduct}
              />}
            </div>
          </>
        )}
      </StyledRoute>
    )
  }

  async setCustomTotal (total, extraState = {}) {
    this.state.currentPayment.patch(
      {
        total,
        ...extraState
      }
    )
    this.saveCurrentPayment({})
  }
}

Route.propTypes = {
  user: PropTypes.object,
  userPreferences: PropTypes.object,
  autoUi: PropTypes.arrayOf(PropTypes.string)
}
