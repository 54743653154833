/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import GenericModal from '../../../components/modals/generic'
import {
  DebouncedForm,
  Switch,
  Input,
  Loading,
  Button,
  icons,
} from '@openbox-app-shared'
import { log } from '../../../log'
import { dispatch } from '../../../redux'

const StyledModal = styled.div`
  .component--modal {
    max-width: 30rem;
    padding: 1rem;
  }
`

const ApplicationSection = styled.div`
  margin: 1rem 0;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f9f9f9;
  input {
    margin-bottom: 2rem;
  }
`

const SelectLabel = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
`

const StyledSelect = styled.select`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`

// Fee section styled components
const FeeContainer = styled.div`
  margin: 1rem 0;
  h3 {
    margin-bottom: 1rem;
  }
`

const FeeItem = styled.div`
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f9f9f9;
  margin-bottom: 1rem;
`

const FeeButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
`

const TotalDisplay = styled.div`
  margin-top: 1rem;
  font-weight: bold;
`

const SNameContainer = styled.div`
  margin: 1rem 0;
  h3 {
    margin-bottom: 1rem;
  }
`

const SNameItem = styled.div`
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f9f9f9;
  margin-bottom: 1rem;
`

const SNameButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
`

const PERMISSIONS = [
  { permission: 'show-footer-logo', name: 'Sticky branding' },
  { permission: 'show-footer-buttons', name: 'Flow footer buttons' },
  { permission: 'has-printer', name: 'Remote printer' },
  { permission: 'has-printer-local', name: 'Local printer' },
  { permission: 'vault', name: 'Vault' },
]

function formatDate(date) {
  if (!date) return ''
  const d = new Date(date)
  const month = `${d.getMonth() + 1}`.padStart(2, '0')
  const day = `${d.getDate()}`.padStart(2, '0')
  return `${d.getFullYear()}-${month}-${day}`
}

function ThisModal({ otherUser, onDone, onClose, openInvoices }) {
  const [applications, setApplications] = useState([])
  const [activeAppId, setActiveAppId] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isArchived, setIsArchived] = useState(
    otherUser.tagsInternal.has('a9adf7b2-82e3-450a-9a2a-82ec4ca40f98')
  )
  // New state for direct debit ref
  const [directDebitRef, setDirectDebitRef] = useState(otherUser.directDebitRef || '')

  const activeApplication = applications.find(app => app.id === activeAppId)

  useEffect(() => {
    let isMounted = true
    async function loadApplications() {
      try {
        const allApps = await window.sticky.applications.getAll(true, otherUser.privateKey)
        const userApps = allApps
          .filter((app) => app.userId === otherUser.id)
          .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
        if (isMounted) {
          setApplications(userApps)
          if (userApps.length > 0) {
            setActiveAppId(userApps[0].id)
          }
        }
      } catch (error) {
        console.error('Error loading applications:', error)
      } finally {
        if (isMounted) {
          setIsLoading(false)
        }
      }
    }
    loadApplications()
    return () => { isMounted = false }
  }, [otherUser.id, otherUser.privateKey])

  if (isLoading) {
    return (
      <StyledModal>
        <GenericModal onClose={onClose}>
          <Loading />
        </GenericModal>
      </StyledModal>
    )
  }

  const updateActiveApplication = (changes) => {
    setApplications(prevApps => {
      return prevApps.map(app => {
        if (app.id === activeAppId) {
          Object.assign(app, changes)
          // Send patch to server
          app.patch(changes)
        }
        return app
      })
    })
  }

  // Fixed Fees helpers
  const addFee = () => {
    const currentFees = activeApplication.fees || []
    updateActiveApplication({ fees: [...currentFees, { name: '', value: 0 }] })
  }

  const updateFee = (index, changes) => {
    const newFees = activeApplication.fees.map((fee, i) =>
      i === index ? { ...fee, ...changes } : fee
    )
    updateActiveApplication({ fees: newFees })
  }

  const removeFee = index => {
    const newFees = activeApplication.fees.filter((_, i) => i !== index)
    updateActiveApplication({ fees: newFees })
  }

  const duplicateFee = index => {
    const currentFees = activeApplication.fees || []
    const feeToDuplicate = currentFees[index]
    updateActiveApplication({ fees: [...currentFees, { ...feeToDuplicate }] })
  }

  // SName helpers
  const addSName = () => {
    const currentSNames = activeApplication.sName || []
    updateActiveApplication({ sName: [...currentSNames, ''] })
  }

  const updateSName = (index, newValue) => {
    const newSNames = (activeApplication.sName || []).map((name, i) =>
      i === index ? newValue : name
    )
    updateActiveApplication({ sName: newSNames })
  }

  const removeSName = index => {
    const newSNames = (activeApplication.sName || []).filter((_, i) => i !== index)
    updateActiveApplication({ sName: newSNames })
  }

  const duplicateSName = index => {
    const currentSNames = activeApplication.sName || []
    const nameToDuplicate = currentSNames[index]
    updateActiveApplication({ sName: [...currentSNames, nameToDuplicate] })
  }

  // AdHoc Fee helpers (now working with an array)
  const addAdhocFee = () => {
    const currentAdhocFees = activeApplication.adhocFees || []
    updateActiveApplication({ adhocFees: [...currentAdhocFees, { name: '', value: 0 }] })
  }

  const updateAdhocFee = (index, changes) => {
    const newAdhocFees = (activeApplication.adhocFees || []).map((fee, i) =>
      i === index ? { ...fee, ...changes } : fee
    )
    updateActiveApplication({ adhocFees: newAdhocFees })
  }

  const removeAdhocFee = index => {
    const newAdhocFees = (activeApplication.adhocFees || []).filter((_, i) => i !== index)
    updateActiveApplication({ adhocFees: newAdhocFees })
  }

  const duplicateAdhocFee = index => {
    const currentAdhocFees = activeApplication.adhocFees || []
    const feeToDuplicate = currentAdhocFees[index]
    updateActiveApplication({ adhocFees: [...currentAdhocFees, { ...feeToDuplicate }] })
  }

  return (
    <StyledModal>
      <GenericModal onGood={() => onDone(otherUser, applications)} onClose={onClose}>
        <DebouncedForm
          onChange={(key, value) => {
            if (key === 'setIsArchived') {
              if (value) {
                otherUser.tagsInternal.add('a9adf7b2-82e3-450a-9a2a-82ec4ca40f98')
              } else {
                otherUser.tagsInternal.delete('a9adf7b2-82e3-450a-9a2a-82ec4ca40f98')
              }
              setIsArchived(value)
              return
            }
            if (key.startsWith('permission--')) {
              const whichPermission = key.substring('permission--'.length)
              otherUser.permissions.toggle(whichPermission)
            }
          }}
        >
          {PERMISSIONS.map(p => (
            <Switch
              key={p.permission}
              checked={otherUser.can(p.permission)}
              name={`permission--${p.permission}`}
            >
              {p.name}
            </Switch>
          ))}

          <hr />

          <Switch checked={isArchived} name="setIsArchived">
              Archived
          </Switch>

          {applications.length > 1 && (
            <ApplicationSection>
              <SelectLabel htmlFor="application-select">Select Application</SelectLabel>
              <StyledSelect
                id="application-select"
                value={activeAppId || ''}
                onChange={e => setActiveAppId(e.target.value)}
              >
                {applications.map(app => (
                  <option key={app.id} value={app.id}>
                    {app.name || `Application ${app.id}`}
                  </option>
                ))}
              </StyledSelect>
            </ApplicationSection>
          )}

          <Button
            isSecondary
            onClick={openInvoices}
          >
              Open Invoices
          </Button>

          {activeApplication && (
            <ApplicationSection>
              <Input
                label="Go Live Date"
                type="date"
                value={
                  activeApplication.goLiveDate
                    ? formatDate(activeApplication.goLiveDate * 1000)
                    : ''
                }
                onChange={newValue => {
                  const dateParts = newValue.split('-') // [YYYY, MM, DD]
                  const dateObj = new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
                  updateActiveApplication({ goLiveDate: Math.floor(dateObj.getTime() / 1000) })
                }}
              />

              <Input
                label="Solution Name"
                value={activeApplication.solutionName || ''}
                onChange={newValue => updateActiveApplication({ solutionName: newValue })}
              />

              <Input
                label="P Name"
                value={activeApplication.pName || ''}
                onChange={newValue => updateActiveApplication({ pName: newValue })}
              />

              <SNameContainer>
                <h3>S Names</h3>
                {activeApplication.sName && activeApplication.sName.length > 0 ? (
                  activeApplication.sName.map((name, index) => (
                    <SNameItem key={index}>
                      <Input
                        label={`S Name ${index + 1}`}
                        value={name || ''}
                        onChange={newValue => updateSName(index, newValue)}
                      />
                      <SNameButtons>
                        <Button
                          InlineIcon={icons.copy}
                          isSecondary
                          onClick={() => duplicateSName(index)}
                        >
                                  Duplicate
                        </Button>
                        <Button
                          InlineIcon={icons.delete}
                          backgroundColor="#ff3838"
                          onClick={() => removeSName(index)}
                        >
                                  Delete
                        </Button>
                      </SNameButtons>
                    </SNameItem>
                  ))
                ) : (
                  <></>
                )}
                <Button InlineIcon={icons.add} isSecondary onClick={addSName}>
                      Add S Name
                </Button>
              </SNameContainer>

              <Input
                label="Agreed Percentage"
                type="number"
                value={activeApplication.pAgreedPercent || ''}
                onChange={newValue =>
                  updateActiveApplication({ pAgreedPercent: newValue })
                }
              />

              <Input
                label="Agreed Pence Rate"
                type="number"
                value={activeApplication.pAgreedPenceRate || ''}
                onChange={newValue => {
                  updateActiveApplication({ pAgreedPenceRate: newValue })
                }}
              />

              {/* Dynamic Fixed Fees list */}
              <FeeContainer>
                <h3>Fixed Fees</h3>
                {activeApplication.fees && activeApplication.fees.length > 0 ? (
                  activeApplication.fees.map((fee, index) => (
                    <FeeItem key={index}>
                      <div style={{ display: 'flex', gap: '1rem' }}>
                        <Input
                          label="Name"
                          value={fee.name || ''}
                          onChange={newValue => updateFee(index, { name: newValue })}
                        />
                        <Input
                          label={`Amount (${otherUser.currency})`}
                          type="number"
                          value={fee.value || ''}
                          onChange={newValue => updateFee(index, { value: newValue })}
                        />
                      </div>
                      <FeeButtons>
                        <Button
                          InlineIcon={icons.copy}
                          isSecondary
                          onClick={() => duplicateFee(index)}
                        >
                                  Duplicate
                        </Button>
                        <Button
                          InlineIcon={icons.delete}
                          backgroundColor="#ff3838"
                          onClick={() => removeFee(index)}
                        >
                                  Delete
                        </Button>
                      </FeeButtons>
                    </FeeItem>
                  ))
                ) : (
                  <></>
                )}
                <Button InlineIcon={icons.add} isSecondary onClick={addFee}>
                      Add Fee
                </Button>
                <TotalDisplay>
                      Total Monthly Fixed Fees ({otherUser.currency}):{' '}
                  {activeApplication.fees
                    ? activeApplication.fees.reduce(
                      (total, fee) => (parseFloat(total) + parseFloat(fee.value)).toFixed(2),
                      0
                    )
                    : 0}
                </TotalDisplay>
              </FeeContainer>

              {/* Dynamic AdHoc One-off Fees Section */}
              <FeeContainer>
                <h3>AdHoc One-off Fees (On Account Customers Only)</h3>
                {activeApplication.adhocFees && activeApplication.adhocFees.length > 0 ? (
                  activeApplication.adhocFees.map((fee, index) => (
                    <FeeItem key={index}>
                      <div style={{ display: 'flex', gap: '1rem' }}>
                        <Input
                          label="Name"
                          value={fee.name || ''}
                          onChange={newValue => updateAdhocFee(index, { name: newValue })}
                        />
                        <Input
                          label={`Value (${otherUser.currency})`}
                          type="number"
                          value={fee.value || ''}
                          onChange={newValue => updateAdhocFee(index, { value: newValue })}
                        />
                      </div>
                      <FeeButtons>
                        <Button
                          InlineIcon={icons.copy}
                          isSecondary
                          onClick={() => duplicateAdhocFee(index)}
                        >
                                  Duplicate
                        </Button>
                        <Button
                          InlineIcon={icons.delete}
                          backgroundColor="#ff3838"
                          onClick={() => removeAdhocFee(index)}
                        >
                                  Delete
                        </Button>
                      </FeeButtons>
                    </FeeItem>
                  ))
                ) : (
                  <></>
                )}
                <Button InlineIcon={icons.add} isSecondary onClick={addAdhocFee}>
                      Add AdHoc Fee
                </Button>
              </FeeContainer>

              <Input
                label={`Minimum Monthly Service Charge (${otherUser.currency})`}
                type="number"
                value={activeApplication.pMmsc || ''}
                onChange={newValue => updateActiveApplication({ pMmsc: newValue })}
              />
            </ApplicationSection>
          )}

          <Input
            label="Direct Debit Ref"
            value={directDebitRef}
            onChange={newValue => {
              setDirectDebitRef(newValue)
              otherUser.directDebitRef = newValue
            }}
          />
        </DebouncedForm>
      </GenericModal>
    </StyledModal>
  )
}

export default {
  trigger: ({ setState, why, otherUser }) => {
    setState({ USER_EDIT: { why, otherUser } })
  },
  render: function USER_EDIT({ state, setState }) {
    const { USER_EDIT } = state
    if (!USER_EDIT) return null
    const { why, otherUser } = USER_EDIT
    console.log('[USER_EDIT] [render]', { why, otherUser })
    const onDone = async (updatedUser, updatedApps) => {
      const whatHappened = { why, user: updatedUser, applications: updatedApps }
      log('[USER_EDIT] [Modal->render->onDone] whatHappened', whatHappened)
      dispatch('LOADING')
      try {
        // Save user directDebitRef, etc.
        await window.sticky.users.save(updatedUser, [
          'tagsInternal',
          'permissions',
          'directDebitRef'
        ])
        // Save all application changes, including 'sName' and new adhoc fee fields.
        await window.sticky.applications.saveAll(updatedApps, null, updatedUser.privateKey)
      } catch (e) {
        dispatch('SHOW_MESSAGE', { message: <p>{e.message}</p>, canBeBadded: '' })
      }
      dispatch('STOP_LOADING')
      dispatch('USER_EDIT_GOOD')
      setState({ USER_EDIT: undefined })
    }
    const onClose = () => {
      setState({ USER_EDIT: undefined })
    }
    return (
      <ThisModal
        otherUser={otherUser}
        openInvoices = { () => { dispatch('INVOICES', { otherUser }) }}
        onDone={onDone}
        onClose={onClose}
      />
    )
  },
}
